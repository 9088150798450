import React from "react";
import { useIsMobile } from "utils/hooks";
import { UIProps } from "components/base/types";
import { Row, Column } from "components/base/layout";

const AppLayoutContainer = (props: UIProps) => {
  const isMobile = useIsMobile();

  if (isMobile === undefined) {
    return <></>;
  }

  return isMobile ? (
    <Column maxHeight="100vh" flexDirection="column-reverse" {...props} />
  ) : (
    <Row minWidth="100vw" minHeight="100vh" {...props} />
  );
};

export default AppLayoutContainer;
