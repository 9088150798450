export const pruneUndefinedValuesFromObject = (
  obj: Record<string | number, any>
) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object")
      pruneUndefinedValuesFromObject(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};
