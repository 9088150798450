import { v4 as uuidv4 } from "uuid";

export const getUniqueFileNameParts = (fileName: string) => {
  const splitName = fileName.split(".");
  const extension = splitName[splitName.length - 1];
  const baseFileName = splitName.slice(0, splitName.length - 1).join(".");
  const hash = uuidv4();
  return {
    fileName: baseFileName + "." + hash + "." + extension,
    extension,
  };
};
