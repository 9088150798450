import React from "react";
import { Redirect, useParams } from "react-router-dom";

const RedirectToLogin = () => {
  const { familyId } = useParams();

  return <Redirect to={`/${familyId}/login`} />;
};

export default RedirectToLogin;
