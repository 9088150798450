import React from "react";
import * as firebase from "firebase";
import { useForm, Controller } from "react-hook-form";

import { Story, MemberWithRelationships } from "core";
import { useSelectedTimelineMember } from "pages/Tree/context";

import { Input, Icon, Button } from "components/base";
import { Pane, Row } from "components/base/layout";
import { SubmitButton, Label } from "components/base/Form";

interface Form {
  name: string;
}

const saveName = async (
  member: MemberWithRelationships,
  story: Story,
  humanName: string
) => {
  const ref = firebase
    .database()
    .ref("families")
    .child("kurum")
    .child("entities")
    .child(member.id)
    .child("events")
    .child(story.eventId)
    .child("stories")
    .child(story.id);

  await ref.update({ humanName });
};

const deleteStory = async (story: Story, member: MemberWithRelationships) => {
  await firebase
    .storage()
    .ref("families")
    .child("kurum")
    .child("entities")
    .child(member.id)
    .child("stories")
    .child(story.fileName)
    .delete();

  await firebase
    .database()
    .ref("families")
    .child("kurum")
    .child("entities")
    .child(member.id)
    .child("events")
    .child(story.eventId)
    .child("stories")
    .child(story.id)
    .remove();
};

const EditStoryForm = ({
  story,
  isVisible,
  closePopover,
}: {
  story: Story;
  isVisible: boolean;
  closePopover: () => void;
}) => {
  const member: MemberWithRelationships = useSelectedTimelineMember();
  const { handleSubmit, control } = useForm<Form>({
    defaultValues: { name: story.humanName },
  });

  const onSubmit = async (form: Form) => {
    const { name } = form;
    // setName(name);

    await saveName(member, story, name);
    closePopover();
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(onSubmit)}>
      {isVisible && (
        <>
          <Label fontSize={14} marginBottom={8}>
            What should we name this story?
          </Label>
          <Row>
            <Controller
              as={Input}
              name="name"
              control={control}
              placeholder="Name"
            />

            <SubmitButton marginLeft={6}>Save</SubmitButton>
            <Button
              flexShrink={0}
              marginLeft={6}
              onClick={() =>
                window.confirm("Delete this story?") &&
                deleteStory(story, member)
              }
              icon={<Icon name="fa-trash" />}
            />
          </Row>
        </>
      )}
    </Pane>
  );
};

export default EditStoryForm;
