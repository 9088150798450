import React, { useState, useRef, useEffect, useMemo } from "react";
import getFamilyUnits from "./getFamilyUnits";
import { FamilyUnit } from "pages/Tree/types";
import FamilyUnitBlock from "../FamilyUnitBlock";
import styled from "styled-components";
import { UIProps } from "components/base/types";
import { MemberWithRelationships, MemberId } from "core";
import { Row } from "components/base/layout";
import { useIsMobile } from "utils/hooks";
import MemberBlock from "../MemberBlock";

const getGrandparentIds = (
  membersMap: Map<MemberId, MemberWithRelationships>,
  primaryMember: MemberWithRelationships | undefined
): Record<MemberId, MemberId> => {
  let grandparents: Record<MemberId, MemberId> = {};

  if (primaryMember) {
    primaryMember.parents.forEach((parentId) => {
      const parent: MemberWithRelationships | undefined = membersMap.get(
        parentId
      );

      if (parent && parent.parents[0]) {
        grandparents[parentId] = parent.parents[0];
      }
    });
  }
  return grandparents;
};

const SubtreeContainer = ({ baseMemberBlockRef, ...props }: any) => {
  const isMobile = useIsMobile();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (baseMemberBlockRef && ref.current) {
      const left = baseMemberBlockRef.getBoundingClientRect().left;
      const scrollDistance = Math.round(left - window.innerWidth / 2 + 170);

      ref.current.scrollTo(scrollDistance, 0);
      setVisible(true);
    }
  }, [baseMemberBlockRef, ref]);

  return (
    <StyledSubtreeContainer
      ref={ref}
      isMobile={isMobile}
      isVisible={visible}
      {...props}
    />
  );
};

const StyledSubtreeContainer = styled.div`
  overflow: auto;
  padding-top: 10px;
  margin-left: -24px;
  opacity: ${(props: any) => (props.isVisible ? 1 : 0)};
  display: ${(props: any) => (props.isMobile ? "flex" : "block")};
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100% + 24px);
`;

const Subtree = React.memo((props: any) => {
  const {
    setMemberToEdit,
    setPrimaryParentId,
    primaryMember,
    members,
    membersMap,
  } = props;

  const [baseMemberBlockRef, setBaseMemberBlockRef] = useState<
    React.MutableRefObject<HTMLDivElement>
  >();

  const primaryFamilyUnit: FamilyUnit | null = useMemo(
    () => getFamilyUnits(members, primaryMember?.parents[0]),
    [primaryMember, members]
  );

  const grandParentFamilyUnits: Record<MemberId, FamilyUnit> = useMemo(() => {
    const familyUnits: Record<MemberId, FamilyUnit> = {};
    const grandparentIds = getGrandparentIds(membersMap, primaryMember);

    for (const parentId in grandparentIds) {
      const grandParentFamilyUnit: FamilyUnit | null = getFamilyUnits(
        members,
        grandparentIds[parentId],
        primaryMember?.parents
      );

      if (grandParentFamilyUnit) {
        familyUnits[parentId] = grandParentFamilyUnit;
      }
    }
    return familyUnits;
  }, [primaryMember, membersMap]);

  const hasFirstGrandparent =
    primaryMember &&
    primaryMember.parents.length > 0 &&
    grandParentFamilyUnits[primaryMember.parents[0]];

  const hasSecondGrandparent =
    primaryMember &&
    primaryMember.parents.length > 1 &&
    grandParentFamilyUnits[primaryMember.parents[1]];

  return (
    <SubtreeContainer baseMemberBlockRef={baseMemberBlockRef}>
      <Row
        marginLeft={-24}
        centerX={!hasFirstGrandparent && !hasSecondGrandparent}
      >
        {primaryMember && !primaryMember.parents.length && (
          <Row center width="100%" height="100%" minHeight={300}>
            <MemberBlock
              member={primaryMember}
              hasChild={primaryMember.children.length > 0}
              isFirstPartner
              isLastPartner
              isFirstParent
              isLastParent
              isFirstChild
              isLastChild
              setPrimaryParentId={setPrimaryParentId}
              setMemberToEdit={setMemberToEdit}
              setBaseMemberBlockRef={setBaseMemberBlockRef}
            />
          </Row>
        )}

        {hasFirstGrandparent && (
          <FamilyUnitBlock
            familyUnit={grandParentFamilyUnits[primaryMember.parents[0]]}
            isFinalAncestor
            setPrimaryParentId={setPrimaryParentId}
            setMemberToEdit={setMemberToEdit}
            paddingX={0}
            width="fit-content"
            connectToSiblingOnRightInGeneration={1}
          />
        )}
        {primaryFamilyUnit && (
          <FamilyUnitBlock
            setBaseMemberBlockRef={setBaseMemberBlockRef}
            familyUnit={primaryFamilyUnit}
            isFinalAncestor
            setPrimaryParentId={setPrimaryParentId}
            setMemberToEdit={setMemberToEdit}
            paddingX={0}
            isFirstChild={!hasFirstGrandparent}
            isLastChild={!hasSecondGrandparent}
            width="fit-content"
            marginTop={131}
            connectToSiblingsInGeneration={0}
          />
        )}
        {hasSecondGrandparent && (
          <FamilyUnitBlock
            familyUnit={grandParentFamilyUnits[primaryMember.parents[1]]}
            isFinalAncestor
            setPrimaryParentId={setPrimaryParentId}
            setMemberToEdit={setMemberToEdit}
            paddingX={0}
            width="fit-content"
            connectToSiblingOnLeftInGeneration={1}
          />
        )}
      </Row>
    </SubtreeContainer>
  );
});

export default Subtree;
