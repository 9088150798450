import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Select, Modal } from "antd";
import { getMemberName } from "utils/member";
import api from "utils/api";
import * as firebase from "firebase";
import { MemberWithRelationships, Relationships, Member } from "core";

import { Pane, Column } from "components/base/layout";
import ContentContainer from "components/ContentContainer";
import EditMemberPanel from "pages/Tree/components/EditMemberPanel";

const CreateFamily = ({
  setUserId,
}: {
  setUserId: (userId: string) => void;
}) => {
  const { familyId } = useParams();
  const [member, setMember] = useState<Member>();

  const createMember = async (
    familyMember: Partial<Member>,
    relationships: Relationships
  ) => {
    const newMember: Member = await api.post<Member, any>(
      `/family/${familyId}/members`,
      {
        ...relationships,
        familyMember,
      }
    );

    firebase
      .database()
      .ref("families")
      .child(familyId)
      .set({ createdAt: Date.now(), adminMemberIds: [newMember.id] });

    setMember(newMember);
    setUserId(newMember.id);
  };

  return (
    <ContentContainer is={Column} alignItems="flex-start">
      <Modal
        title="Create Family"
        visible
        zIndex={2000}
        width={460}
        footer={null}
        bodyStyle={{ borderRadius: 8 }}
      >
        <Pane marginBottom={24} textAlign="center" fontSize={15}>
          Let's create a new family. To start, who are you?
        </Pane>
        <EditMemberPanel
          allMembers={[]}
          member={{}}
          createOrUpdateMember={createMember}
          closeEditMemberPanel={() => {
            console.log("close");
          }}
        />
      </Modal>
    </ContentContainer>
  );
};

export default CreateFamily;
