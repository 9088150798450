import * as firebase from "firebase";
import {
  MemberWithRelationships,
  LifeEventType,
  FirebaseKey,
  LifeEvent,
  MemberId,
} from "core";

const getBirthEvent = (
  member: MemberWithRelationships,
  type?: LifeEventType,
  id?: string,
  details?: any
): LifeEvent | null =>
  member.birthYear && member.birthTimestamp
    ? {
        memberId: member.id,
        id: `${member.id}-${id || LifeEventType.Birth}`,
        type: type || LifeEventType.Birth,
        isInferred: true,
        createdBy: member.id,
        details: {
          location: member.birthPlace,
          ...(details || {}),
        },
        timestamp: {
          components: {
            day: member.birthDay,
            month: member.birthMonth,
            year: member.birthYear,
          },
          raw: member.birthTimestamp,
        },
      }
    : null;

const getChildbirthEvent = (
  member: MemberWithRelationships
): LifeEvent | null =>
  getBirthEvent(
    member,
    LifeEventType.Childbirth,
    `${member.id}-${LifeEventType.Childbirth}`,
    {
      childName: member.firstName,
    }
  );

const addBirthEvent = (
  inferredEvents: LifeEvent[],
  member: MemberWithRelationships
): LifeEvent[] => {
  const birthEvent: LifeEvent | null = getBirthEvent(member);

  if (birthEvent) {
    return [...inferredEvents, birthEvent];
  }
  return inferredEvents;
};

const addChildBirthEvents = (
  inferredEvents: LifeEvent[],
  member: MemberWithRelationships,
  membersMap: Map<MemberId, MemberWithRelationships>
): LifeEvent[] => {
  const childBirthEvents: LifeEvent[] = [];
  member.children.forEach((childKey: MemberId) => {
    const child = membersMap.get(childKey);

    if (child) {
      const childBirthEvent: LifeEvent | null = getChildbirthEvent(child);

      if (childBirthEvent) {
        childBirthEvents.push(childBirthEvent);
      }
    }
  });

  return [...inferredEvents, ...childBirthEvents];
};

const addWeddingEvents = async (
  inferredEvents: LifeEvent[],
  member: MemberWithRelationships,
  membersMap: Map<MemberId, MemberWithRelationships>
): Promise<LifeEvent[]> => {
  if (!!member.partners.length) {
    const spouseMember = membersMap.get(member.partners[0]);

    if (spouseMember) {
      const spouseEventsSnapshot = await firebase
        .database()
        .ref("families")
        .child("kurum")
        .child("entities")
        .child(spouseMember.id)
        .child("events")
        .once("value");

      const spouseEvents: Record<
        FirebaseKey,
        LifeEvent
      > = spouseEventsSnapshot.val();

      if (spouseEvents) {
        const weddingEvent = Object.values(spouseEvents).find(
          (event: LifeEvent) => event.type === LifeEventType.Wedding
        );

        if (weddingEvent) {
          return [
            ...inferredEvents,
            {
              ...weddingEvent,
              memberId: member.id,
              isInferred: true,
              createdBy: member.id,
              id: `${member.id}-${LifeEventType.Wedding}`,
              details: {
                ...weddingEvent.details,
                to: spouseMember.id,
                toName: spouseMember.firstName,
              },
            },
          ];
        }
      }
    }
  }

  return inferredEvents;
};

const getInferredEvents = async (
  member: MemberWithRelationships,
  membersMap: Map<MemberId, MemberWithRelationships>
) => {
  let inferredEvents: LifeEvent[] = [];

  inferredEvents = addBirthEvent(inferredEvents, member);
  inferredEvents = addChildBirthEvents(inferredEvents, member, membersMap);
  inferredEvents = await addWeddingEvents(inferredEvents, member, membersMap);

  return inferredEvents;
};

export default getInferredEvents;
