import React, { useState } from "react";
import moment from "moment";

import { Pane } from "components/base/layout";

const DurationIndicator = ({ duration, timeElapsed }: any) => {
  const [isIndicatorShowingTimeLeft, setIsIndicatorShowingTimeLeft] = useState<
    boolean
  >(true);
  const timeLeft = duration - timeElapsed;

  const time = isIndicatorShowingTimeLeft ? timeLeft : timeElapsed;

  const formattedTime = isNaN(time)
    ? ""
    : (isIndicatorShowingTimeLeft ? "-" : " ") +
      moment.utc(time * 1000).format("mm:ss");
  return (
    <Pane
      is="span"
      width={37}
      textAlign="right"
      flexShrink={0}
      marginLeft={8}
      marginTop={3}
      fontSize={11}
      cursor="pointer"
      onClick={() => setIsIndicatorShowingTimeLeft(!isIndicatorShowingTimeLeft)}
    >
      {formattedTime}
    </Pane>
  );
};

export default DurationIndicator;
