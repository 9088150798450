import React from "react";

import { Input as AntdInput } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";

type AntdInputProps = React.ComponentProps<typeof AntdInput>;

export const Input = (props: AntdInputProps & UIProps) => (
  <Box is={AntdInput} {...props} />
);

export default Input;
