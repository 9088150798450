import { LifeEvent, LifeEventType, WorkTypes } from "core";

export const getFormattedEventName = (event: LifeEvent): string => {
  switch (event.type) {
    case LifeEventType.Moved:
      return `Moved to ${event.details?.toLocation || "..."}`;
    case LifeEventType.Wedding:
      return `Married ${event.details?.toName || "..."}`;
    case LifeEventType.School:
      return `Attended ${event.details?.schoolName?.split(",")[0] || "..."}`;
    case LifeEventType.Job:
      const prefixText = "Started work ";
      const workplaceText = `at ${event.details.workplace.split(",")[0]}`;
      if (event.details?.workplace) {
        let typeText = "",
          roleText = "";
        if (event.details.role) {
          roleText = `as ${event.details.role} `;
        }
        if (event.details.type && event.details.type !== WorkTypes.FullTime) {
          typeText = ` (${event.details.type})`;
        }
        return `${prefixText}${roleText}${workplaceText}${typeText}`;
      }
      return `${prefixText}...`;
    case LifeEventType.Childbirth:
      return `Child born, ${event.details?.childName || "..."}`;
    case LifeEventType.Birth:
      return `Born in ${event.details?.location || "..."}`;
    case LifeEventType.Trip:
      if (event.details?.description) {
        return event.details?.description;
      }
      return `Trip to ${event.details?.location || "..."}`;
    case LifeEventType.Photo:
      return event.details?.description || "...";
    case LifeEventType.Death:
      return `Passed away`;
    case LifeEventType.Milestone:
      return event.details?.milestoneText || "...";
  }

  return event.type;
};
