const OTHER_COLOR = "rgb(0,0,0,0.2)";
const GENDER_TO_COLOR: Record<string, string> = {
  other: OTHER_COLOR,
  male: "$blue-0",
  female: "$red-0",
};

export const getGenderColor = (gender: string): string => {
  return gender ? GENDER_TO_COLOR[gender] : OTHER_COLOR;
};
