export const COLORS = {
  "$gray-0": "#FFFFFF",
  "$gray-1": "#F5F7FA",
  "$gray-2": "#E7ECF3",
  "$gray-3": "#D6DBE7",
  "$gray-4": "#C0C6D9",
  "$gray-5": "#7E89A9",
  "$gray-6": "#475872",
  "$gray-7": "#394455",
  "$gray-8": "#242A35",
  "$slate-1": "#f8f9fc",
  "$slate-2": "#C9C6E6",
  "$blue-0": "#A6CCF2",
  "$blue-1": "#2063CB",
  "$red-0": "#EFC8D5",
};
