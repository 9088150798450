import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useParams } from "react-router-dom";
import RedirectToLogin from "./RedirectToLogin";
import * as firebase from "firebase";
import { useListVals } from "react-firebase-hooks/database";

const getDoesFamilyExist = async (familyId: string): Promise<boolean> => {
  const familySnapshot = await firebase
    .database()
    .ref("families")
    .child(familyId)
    .once("value");
  return !!familySnapshot.val();
};

const useDoesFamilyExist = (familyId: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [doesFamilyExist, setDoesFamilyExist] = useState<boolean>(false);

  const syncGetDoesFamilyExist = async (familyId: string) => {
    const doesFamilyExist = await getDoesFamilyExist(familyId);
    setDoesFamilyExist(doesFamilyExist);
    setLoading(false);
  };

  useEffect(() => {
    syncGetDoesFamilyExist(familyId);
  }, []);

  return [loading, doesFamilyExist];
};

const CheckIfFamilyExistsThenRedirect = () => {
  const { familyId } = useParams();
  const [loading, doesFamilyExist] = useDoesFamilyExist(familyId);

  if (loading) {
    return <></>;
  }

  if (doesFamilyExist) {
    return <RedirectToLogin />;
  }

  return <Redirect to={`/${familyId}/create`} />;
};

export default CheckIfFamilyExistsThenRedirect;
