import { createContext, useContext } from "react";
import { LifeEvent } from "core";

export interface IEvent {
  event: LifeEvent | undefined;
}

export const EventContext = createContext<IEvent>({
  event: undefined,
});

export const useEvent = (): LifeEvent => {
  const {
    event,
  }: {
    event: LifeEvent | undefined;
  } = useContext<IEvent>(EventContext);
  const lifeEvent = event as LifeEvent;
  return lifeEvent;
};

export default EventContext;
