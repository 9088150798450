import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const LOCAL_API_HOST = "http://[::1]:3001";
const PRODUCTION_API_HOST = "https://family-cloud-backend.onrender.com/";

const API_HOST =
  process.env.NODE_ENV !== "production" ? LOCAL_API_HOST : PRODUCTION_API_HOST;

// TODO: error handling
async function get<T, R = AxiosResponse<T>>(path: string): Promise<T> {
  const result = await axios.get(API_HOST + path);
  return result.data;
}

async function post<T, B, R = AxiosResponse<T>>(
  path: string,
  body: B,
  config?: AxiosRequestConfig
): Promise<T> {
  const result = await axios.post(API_HOST + path, body, config);
  return result.data;
}

async function put<T, B, R = AxiosResponse<T>>(
  path: string,
  body: B,
  config?: AxiosRequestConfig
): Promise<T> {
  const result = await axios.put(API_HOST + path, body, config);
  return result.data;
}

async function deleteFunction<T, R = AxiosResponse<T>>(
  path: string
): Promise<T> {
  const result = await axios.delete(API_HOST + path);
  return result.data;
}

export default {
  get,
  post,
  put,
  delete: deleteFunction,
};
