import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "uppy/dist/uppy.min.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

declare global {
  interface Window {
    analytics: any;
  }
}

const initFirebase = () =>
  firebase.initializeApp({
    apiKey: "AIzaSyDgK88b7Oh31aGqYrbgbkfNkr4gKrQGXco",
    authDomain: "kudum-2d764.firebaseapp.com",
    databaseURL: "https://kudum-2d764.firebaseio.com",
    projectId: "kudum-2d764",
    storageBucket: "kudum-2d764.appspot.com",
    messagingSenderId: "53825476047",
    appId: "1:53825476047:web:add58358d3f96850762eba",
  });

initFirebase();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
