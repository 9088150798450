import React from "react";
import { H1 } from "components/base/type";
import ContentContainer from "components/ContentContainer";

const Directory = () => {
  return (
    <ContentContainer>
      <H1>Guru's Family</H1>
    </ContentContainer>
  );
};

export default Directory;
