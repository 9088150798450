import { MemberId } from "./member";
import { Story } from "./story";
import { FirebaseKey } from "./common";

export enum LifeEventType {
  Moved = "Moved",
  Wedding = "Wedding",
  School = "School",
  Job = "Job",
  Milestone = "Milestone",
  Childbirth = "Childbirth",
  Birth = "Birth",
  Death = "Death",
  Trip = "Trip",
  Photo = "Photo",
}

export enum Privacy {
  Public = "Public",
  ExtendedFamily = "ExtendedFamily",
  FamilyUnit = "FamilyUnit",
  Me = "Me",
  Custom = "Custom",
}

export enum WorkTypes {
  Weekend = "Weekend",
  Internship = "Internship",
  PartTime = "Part-time",
  FullTime = "Full-time",
}

export type TimeComponents = {
  year: number;
  month?: number;
  day?: number;
};

export type TimeDetails = {
  isApproximate?: boolean;
  components: TimeComponents;
  raw: string;
};

export interface LifeEvent {
  memberId: MemberId;
  createdBy: MemberId;
  type: LifeEventType;
  details: any;
  timestamp: TimeDetails;
  privacy?: Privacy;
  id: FirebaseKey;
  isInferred?: boolean;
  stories?: Record<FirebaseKey, Story>;
}

export interface LifeEventMetaData {
  id: LifeEventType;
  isHiddenFromAddMenus?: boolean;
}

export const LifeEventMeta: Record<LifeEventType, LifeEventMetaData> = {
  [LifeEventType.Moved]: {
    id: LifeEventType.Moved,
  },
  [LifeEventType.Wedding]: {
    id: LifeEventType.Wedding,
  },
  [LifeEventType.School]: {
    id: LifeEventType.School,
  },
  [LifeEventType.Milestone]: {
    id: LifeEventType.Milestone,
  },
  [LifeEventType.Job]: {
    id: LifeEventType.Job,
  },
  [LifeEventType.Childbirth]: {
    id: LifeEventType.Childbirth,
  },
  [LifeEventType.Trip]: {
    id: LifeEventType.Trip,
  },
  [LifeEventType.Photo]: {
    id: LifeEventType.Photo,
  },
  [LifeEventType.Birth]: {
    id: LifeEventType.Birth,
    isHiddenFromAddMenus: true,
  },
  [LifeEventType.Death]: {
    id: LifeEventType.Death,
    isHiddenFromAddMenus: true,
  },
};
