import React from "react";
import Box, { propNames, splitProps } from "ui-box";
import { withTheme } from "styled-components";

/* Segment's `Box` library allows us to use CSS properties as function properties -- thereby
 * removing CSS as a side-effect of component functions.
 *
 * Pane is a wrapper around Box that additionally replaces any CSS prop value
 * that begins with "$" with the corresponding token from our design system.
 * e.g.: <Pane backgroundColor="$gray-1"/>.
 *
 * This will eventually enable us to remove all arbitrary magic numbers
 * for padding and margins too,
 * e.g. <Pane padding="$padding-S" marginRight="$margin-M"/>
 */

const reactHtmlAttributes = [
  "children",
  "href",
  "htmlType",
  "to",
  "src",
  "target",
  "rel",
  "className",
  "placeholder",
  "type",
  "value",
  "alt",
  "title",
  "exact",
  "onFocus",
  "onBlur",
  "onKeyDown",
  "onKeyPress",
  "onKeyUp",
  "onChange",
  "onInput",
  "onInvalid",
  "onSubmit",
  "onClick",
  "onContextMenu",
  "onDoubleClick",
  "onDrag",
  "onDragEnd",
  "onDragEnter",
  "onDragExit",
  "onDragLeave",
  "onDragOver",
  "onDragStart",
  "onDrop",
  "onMouseDown",
  "onMouseEnter",
  "onMouseLeave",
  "onMouseMove",
  "onMouseOut",
  "onMouseOver",
  "onMouseUp",
  "onSelect",
  "onScroll",
];

const splitAttributes: string[] = [...reactHtmlAttributes, ...propNames];

const getDesignTokenFromThemeOrPropValue = (
  theme: Record<string, any>,
  propValue: any
) => {
  const isPropValueADesignToken = !!theme[propValue];
  return isPropValueADesignToken ? theme[propValue] : propValue;
};

export const Pane = withTheme(({ theme, ...props }: any) => {
  const matchedProps = splitProps<Record<string, string>, string>(
    props,
    splitAttributes
  ).matchedProps;
  const replacedProps = Object.keys(matchedProps).reduce(
    (allProps, currentProp) => ({
      ...allProps,
      [currentProp]: getDesignTokenFromThemeOrPropValue(
        theme,
        matchedProps[currentProp]
      ),
    }),
    {}
  );

  return <Box {...replacedProps} is={props.is} />;
});
