import React from "react";
import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";

export const H1 = (props: UIProps) => (
  <Pane
    is="h1"
    margin={0}
    fontSize={24}
    fontWeight={600}
    color="$gray-7"
    {...props}
  />
);
