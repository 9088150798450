import React, { FunctionComponent, useContext, useState } from "react";
import { Select, Checkbox, InputNumber } from "antd";
import { Controller, Control } from "react-hook-form";

import {
  LifeEventType,
  MemberWithRelationships,
  WorkTypes,
  Months,
} from "core";
import { getMemberName } from "utils/member";
import { keysOfEnum } from "utils/common";
import { TreeContext, ITree } from "pages/Tree/context";
import { Form } from "../index";

import { Input, Image } from "components/base";
import { Row, Pane } from "components/base/layout";
import GeoSuggest from "components/base/GeoSuggest";
import { Field, Label } from "components/base/Form";

import UploadBox, { FileData } from "./UploadBox";

const FormGeoSuggest = ({ value, onChange, ...props }: any) => (
  <GeoSuggest
    defaultValue={value ? [{ address: value }] : undefined}
    onChange={([selected]: any) => {
      if (selected && selected.address) {
        onChange(selected.address);
      }
    }}
    {...props}
  />
);

type DetailFormFieldsProps = {
  control: Control<Record<string, any>> | undefined;
  register: (ref: any) => void;
  watch: () => Form;
};

export const MovedDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <Field>
    <Controller
      render={({ onChange, onBlur, value }: any) => (
        <FormGeoSuggest placeholder="To" value={value} onChange={onChange} />
      )}
      name="details.toLocation"
      control={control}
    />
  </Field>
);

export const JobDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <>
    <Field>
      <Controller
        render={({ onChange, onBlur, value }: any) => (
          <FormGeoSuggest
            placeholder="Workplace"
            value={value}
            onChange={onChange}
          />
        )}
        name="details.workplace"
        control={control}
      />
    </Field>
    <Field>
      <Controller
        as={Input}
        name="details.role"
        control={control}
        placeholder="Role (Optional)"
      />
    </Field>
    <Field>
      <Controller
        as={
          <Select style={{ width: "100%" }}>
            {Object.keys(WorkTypes).map((eachType: string) => (
              <Select.Option
                key={eachType}
                value={WorkTypes[eachType as keyof typeof WorkTypes]}
              >
                {WorkTypes[eachType as keyof typeof WorkTypes]}
              </Select.Option>
            ))}
          </Select>
        }
        name="details.type"
        placeholder="Type"
        control={control}
      />
    </Field>
  </>
);

export const WeddingDetailFormFields = ({
  control,
  register,
  watch,
}: DetailFormFieldsProps) => {
  const { details }: Partial<Form> = watch();
  const { selectedTimelineMember: member, membersMap } = useContext<ITree>(
    TreeContext
  );

  if (!member!.partners.length) {
    return <></>;
  }

  const spouse = membersMap.get(member!.partners[0]);

  if (!spouse) {
    return <></>;
  }

  return (
    <>
      <Field>
        <Label>Marriage to {spouse!.firstName}</Label>
        <input
          ref={register}
          type="hidden"
          name="details.toName"
          value={spouse!.firstName}
        />
        <input
          ref={register}
          type="hidden"
          name="details.to"
          value={spouse!.id}
        />
        <Controller
          render={({ onChange, onBlur, value }: any) => (
            <FormGeoSuggest
              placeholder="Location (optional)"
              value={value}
              onChange={onChange}
            />
          )}
          name="details.location"
          control={control}
        />
      </Field>
      <Field>
        <Row marginBottom={6} centerY>
          <Controller
            render={({ onChange, value }: any) => {
              const checkedProp =
                value === undefined
                  ? {}
                  : {
                      checked: value,
                    };

              return (
                <Checkbox
                  onChange={(e) => onChange(e.target.checked)}
                  {...checkedProp}
                >
                  Annulled
                </Checkbox>
              );
            }}
            name="details.isDivorce"
            control={control}
          />
          {details?.isDivorce && (
            <>
              <Pane marginRight={6} marginLeft="auto">
                <Controller
                  as={InputNumber}
                  name="details.divorceEvent.year"
                  placeholder="Year"
                  control={control}
                  min={1000}
                  max={new Date().getFullYear()}
                  style={{ width: 80 }}
                />
              </Pane>
              <Pane marginRight={6}>
                <Controller
                  as={
                    <Select dropdownStyle={{ zIndex: 3000 }}>
                      {keysOfEnum(Months).map((eachMonth: any) => (
                        <Select.Option
                          key={eachMonth}
                          value={Number(eachMonth)}
                        >
                          {Months[eachMonth]}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                  name="details.divorceEvent.month"
                  placeholder="Month"
                  control={control}
                />
              </Pane>
              <Controller
                as={InputNumber}
                min={1}
                max={31}
                name="details.divorceEvent.day"
                placeholder="Day"
                control={control}
                style={{ width: 60 }}
              />
              <Controller
                render={({ onChange, value }: any) => {
                  const checkedProp =
                    value === undefined
                      ? {}
                      : {
                          checked: value,
                        };

                  return (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      {...checkedProp}
                      style={{ marginLeft: 8 }}
                    >
                      Approx.
                    </Checkbox>
                  );
                }}
                name="details.divorceEvent.isApproximate"
                control={control}
              />
            </>
          )}
        </Row>
      </Field>
    </>
  );
};

export const SchoolDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <Field>
    <Controller
      render={({ onChange, onBlur, value }: any) => (
        <FormGeoSuggest
          placeholder="School"
          value={value}
          onChange={onChange}
        />
      )}
      name="details.schoolName"
      control={control}
    />
  </Field>
);

export const BirthDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <Field>
    <Controller
      render={({ onChange, onBlur, value }: any) => (
        <FormGeoSuggest
          placeholder="Birthplace"
          value={value}
          onChange={onChange}
        />
      )}
      name="details.birthplace"
      control={control}
    />
  </Field>
);

export const MilestoneDetailFormFields = ({
  control,
}: DetailFormFieldsProps) => (
  <>
    <Field>
      <Controller
        as={Input}
        name="details.milestoneText"
        control={control}
        placeholder="Milestone"
      />
    </Field>
    <Field>
      <Controller
        render={({ onChange, onBlur, value }: any) => (
          <FormGeoSuggest
            placeholder="Location (optional)"
            value={value}
            onChange={onChange}
          />
        )}
        name="details.location"
        control={control}
      />
    </Field>
  </>
);

export const TripDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <>
    <Field>
      <Controller
        as={Input}
        name="details.description"
        control={control}
        placeholder="Description"
      />
    </Field>
    <Field>
      <Controller
        render={({ onChange, onBlur, value }: any) => (
          <FormGeoSuggest
            placeholder="Location"
            value={value}
            onChange={onChange}
          />
        )}
        name="details.location"
        control={control}
      />
    </Field>
  </>
);

export const PhotoDetailFormFields = ({
  control,
  register,
}: DetailFormFieldsProps) => {
  const [fileData, setFileData] = useState<FileData | undefined>();
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);

  return (
    <>
      <Field>
        {!isUploadComplete ? (
          <UploadBox
            setFileData={setFileData}
            onCompleteSuccess={() => setIsUploadComplete(true)}
          />
        ) : (
          <Row maxWidth="100%" height={140} centerX>
            <Image
              maxHeight="100%"
              maxWidth="100%"
              src={fileData!.downloadUrl}
            />
          </Row>
        )}
      </Field>
      <input
        ref={register}
        type="hidden"
        name="details.fileName"
        value={fileData?.fileName}
      />
      <input
        ref={register}
        type="hidden"
        name="details.fileType"
        value={fileData?.fileType}
      />
      <input
        ref={register}
        type="hidden"
        name="details.downloadUrl"
        value={fileData?.downloadUrl}
      />
      <Field>
        <Controller
          as={Input}
          name="details.description"
          control={control}
          placeholder="Description"
        />
      </Field>
      <Field>
        <Controller
          render={({ onChange, onBlur, value }: any) => (
            <FormGeoSuggest
              placeholder="Location"
              value={value}
              onChange={onChange}
            />
          )}
          name="details.location"
          control={control}
        />
      </Field>
    </>
  );
};
export const DeathDetailFormFields = ({ control }: DetailFormFieldsProps) => (
  <Field>
    <Controller
      render={({ onChange, onBlur, value }: any) => (
        <FormGeoSuggest
          placeholder="Deathplace"
          value={value}
          onChange={onChange}
        />
      )}
      name="details.deathplace"
      control={control}
    />
  </Field>
);

export const ChildbirthDetailsFormFields = ({
  control,
}: DetailFormFieldsProps) => {
  const { members } = useContext<ITree>(TreeContext);

  return (
    <>
      <Field>
        <Controller
          name="details.child"
          as={
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {members.map((eachMember: MemberWithRelationships) => (
                <Select.Option key={eachMember.id} value={eachMember.id}>
                  {getMemberName(eachMember)}
                </Select.Option>
              ))}
            </Select>
          }
          placeholder="Child"
          control={control}
        />
      </Field>
      <Field>
        <Controller
          as={<GeoSuggest placeholder="Birthplace (optional)" />}
          name="details.birthplace"
          control={control}
        />
      </Field>
    </>
  );
};

const lifeEventTypeToDetailFormFields: Record<
  LifeEventType,
  FunctionComponent<DetailFormFieldsProps>
> = {
  [LifeEventType.Moved]: MovedDetailFormFields,
  [LifeEventType.Wedding]: WeddingDetailFormFields,
  [LifeEventType.School]: SchoolDetailFormFields,
  [LifeEventType.Job]: JobDetailFormFields,
  [LifeEventType.Childbirth]: ChildbirthDetailsFormFields,
  [LifeEventType.Trip]: TripDetailFormFields,
  [LifeEventType.Birth]: BirthDetailFormFields,
  [LifeEventType.Death]: DeathDetailFormFields,
  [LifeEventType.Photo]: PhotoDetailFormFields,
  [LifeEventType.Milestone]: MilestoneDetailFormFields,
};

const LifeEventDetailFormFields = ({
  lifeEventType,
  ...detailFormFieldsProps
}: {
  lifeEventType: LifeEventType;
} & DetailFormFieldsProps) =>
  lifeEventTypeToDetailFormFields[lifeEventType](detailFormFieldsProps);

export default LifeEventDetailFormFields;
