import React from "react";
import { Modal, Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import { SubmitButton, Label } from "components/base/Form";
import { Pane, Row } from "components/base/layout";

interface Form {
  name: string;
}

const NameFileModal = ({ transformingFile, setName }: any) => {
  const { handleSubmit, control } = useForm<Form>();

  const onSubmit = (form: Form) => {
    const { name } = form;
    setName(name);
  };

  return (
    <Modal
      title="Name file"
      visible={!!transformingFile}
      zIndex={4000}
      width={460}
      footer={null}
      bodyStyle={{ borderRadius: 8 }}
    >
      <Pane is="form" onSubmit={handleSubmit(onSubmit)}>
        <Label fontSize={14} marginBottom={8}>
          What should we name this file?
        </Label>
        <Row>
          <Controller
            as={Input}
            name="name"
            control={control}
            placeholder="Name"
          />

          <SubmitButton marginLeft={6}>Save</SubmitButton>
        </Row>
      </Pane>
    </Modal>
  );
};

export default NameFileModal;
