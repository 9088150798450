import React, { useState } from "react";
import * as firebase from "firebase/app";
import { Upload, message } from "antd";
import {
  RcCustomRequestOptions,
  UploadChangeParam,
} from "antd/lib/upload/interface";
import { MemberWithRelationships, URL, FileType } from "core";
import { getUniqueFileNameParts, getFileType } from "utils/common";
import FirebaseUploadBar from "components/FirebaseUploadBar";
import { useSelectedTimelineMember } from "pages/Tree/context";
import { Icon } from "components/base";
import { Pane } from "components/base/layout";

export interface FileData {
  fileName: string;
  downloadUrl: URL;
  fileType: FileType;
}

const UploadBox = ({
  onCompleteSuccess,
  onCompleteError,
  setFileData,
}: {
  setFileData?: (fileData: FileData) => void;
  onCompleteSuccess?: () => void;
  onCompleteError?: () => void;
}) => {
  const member: MemberWithRelationships = useSelectedTimelineMember();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [uploadTask, setUploadTask] = useState<firebase.storage.UploadTask>();

  const customRequest = async ({
    onError,
    onSuccess,
    file,
  }: RcCustomRequestOptions) => {
    console.log("customRequest", file);
    const { fileName, extension } = getUniqueFileNameParts(file.name);
    const ref = firebase
      .storage()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(member.id)
      .child("stories")
      .child(fileName);

    const fileType = getFileType(extension);
    let contentType = "";
    if (fileType === FileType.Image) {
      contentType = "image/jpeg";
    } else if (fileType === FileType.Audio) {
      contentType = "audio/mpeg";
    }

    const metadata = {
      cacheControl: "public,max-age=72000",
      contentType,
    };
    try {
      const uploadTask = ref.put(file, metadata);
      setUploadTask(uploadTask);

      const audio = await uploadTask;
      const downloadUrl = await ref.getDownloadURL();
      setFileData && setFileData({ fileName, downloadUrl, fileType });
      onSuccess(audio, file);
    } catch (e) {
      onError(e);
    }
  };

  const beforeUpload = (file: File, fileList: File[]) => {
    setIsProcessing(true);
    return true;
  };

  const draggerProps: React.ComponentProps<typeof Upload.Dragger> = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest,
    beforeUpload,
    onChange(info: UploadChangeParam) {
      const { status } = info.file;
      console.log(info);
      console.log(info.file.percent, status);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setIsProcessing(false);
        onCompleteSuccess && onCompleteSuccess();
        message.success(`File saved.`);
      } else if (status === "error") {
        setIsProcessing(false);
        onCompleteError && onCompleteError();
        message.error(`Upload failed.`);
      }
    },
  };

  return (
    <>
      <Upload.Dragger {...draggerProps}>
        <Pane textAlign="center">
          <Icon name="fa-inbox" fontSize={30} />
        </Pane>
        <Pane
          textAlign="center"
          fontSize={13}
          paddingY={8}
          paddingX={20}
          opacity={0.7}
        >
          Click or drag file to this area to upload
        </Pane>
      </Upload.Dragger>
      {isProcessing && uploadTask && (
        <FirebaseUploadBar uploadTask={uploadTask} />
      )}
    </>
  );
};

export default UploadBox;
