import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import {
  getMemberNameLine1,
  getMemberNameLine2,
  getGenderColor,
} from "utils/member";
import { MemberWithRelationships, MemberFamilyStatus } from "core";

import { useIsMobile } from "utils/hooks";

import { UIProps } from "components/base/types";
import { Column, Row, Pane } from "components/base/layout";
import Icon from "components/base/Icon";

import MemberTreeStems from "./MemberTreeStems";
import MemberBlockActions from "./MemberBlockActions";

const StyledMemberBlock = styled(Column)`
  width: 200px;
  height: 111px;

  border-width: 1px;
  border-style: solid;
  background-color: ${(props: any) => props.backgroundColor || "white"};
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;

  .onHover > * {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .onHover > * {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.active .onHover > * {
    opacity: 1;
    pointer-events: all;
  }
`;

const TapCaptureLayer = (props: UIProps) => (
  <Pane
    position="fixed"
    zIndex={2000}
    top={0}
    left={0}
    right={0}
    bottom={0}
    {...props}
  />
);

const MemberBlock = ({
  member,
  setPrimaryParentId,
  isFirstChild,
  isLastChild,
  hasChild,
  isFirstPartner,
  isLastPartner,
  isFirstParent,
  isLastParent,
  hasLineage,
  setMemberToEdit,
  setBaseMemberBlockRef,
  continueConnectionOnLeft,
  continueConnectionOnRight,
  ...props
}: {
  member: MemberWithRelationships;
  setPrimaryParentId: (n: string) => void;
  setMemberToEdit: (n: Partial<MemberWithRelationships>) => void;
  isFirstChild?: boolean;
  isLastChild?: boolean;
  hasChild?: boolean;
  isFirstPartner?: boolean;
  isLastPartner?: boolean;
  isFirstParent?: boolean;
  isLastParent?: boolean;
  hasLineage?: boolean;
  setBaseMemberBlockRef?: any;
  continueConnectionOnLeft?: boolean;
  continueConnectionOnRight?: boolean;
} & UIProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (setBaseMemberBlockRef && ref.current) {
      setBaseMemberBlockRef(ref.current);
    }
  }, [ref, setBaseMemberBlockRef]);

  const isCrossedOut =
    member.defaultFamilyStatus === MemberFamilyStatus.CrossedOut;

  return (
    <div ref={ref}>
      <StyledMemberBlock
        className={isActive ? "active" : ""}
        borderColor={getGenderColor(member.gender || "")}
        backgroundColor={isCrossedOut ? "transparent" : "white"}
        margin={10}
        onClick={() => isMobile && setIsActive(!isActive)}
        {...props}
      >
        {isActive && <TapCaptureLayer onClick={() => setIsActive(false)} />}
        <MemberTreeStems
          isFirstChild={isFirstChild}
          isLastChild={isLastChild}
          hasChild={hasChild}
          isFirstPartner={isFirstPartner}
          isLastPartner={isLastPartner}
          isFirstParent={isFirstParent}
          isLastParent={isLastParent}
          hasLineage={hasLineage}
          continueConnectionOnLeft={continueConnectionOnLeft}
          continueConnectionOnRight={continueConnectionOnRight}
          hasParents={member.parents.length > 0}
        />

        <Pane className="onHover">
          <MemberBlockActions
            member={member}
            setPrimaryParentId={setPrimaryParentId}
            setMemberToEdit={setMemberToEdit}
            shouldShowLineageOption={!hasLineage && member.parents.length > 0}
          />
        </Pane>

        <Pane
          color="black"
          fontSize={15}
          fontFamily="Georgia"
          opacity={isCrossedOut ? 0.5 : 1}
        >
          {getMemberNameLine1(member)}
        </Pane>
        <Pane
          color="black"
          fontSize={15}
          fontFamily="Georgia"
          opacity={isCrossedOut ? 0.5 : 1}
        >
          {getMemberNameLine2(member, true)}
        </Pane>
        {(member.birthTimestamp || member.deathTimestamp) && (
          <Pane color="$gray-5" fontSize={12} opacity={isCrossedOut ? 0.5 : 1}>
            {member.birthTimestamp && (
              <>
                {member.birthYear}.{member.birthMonth}.{member.birthDay}
              </>
            )}
            {member.deathTimestamp && (
              <>
                &nbsp;– {member.deathYear}.{member.deathMonth}.{member.deathDay}
              </>
            )}
          </Pane>
        )}
        {member.location && (
          <Row
            color="$gray-5"
            fontSize={12}
            centerY
            opacity={isCrossedOut ? 0.5 : 1}
          >
            <Icon
              color="$gray-4"
              fontSize={10}
              name="fa-map-marker-alt"
              type="fas"
              marginRight={4}
            />
            {member.location.split(",")[0].trim()}
          </Row>
        )}
      </StyledMemberBlock>
    </div>
  );
};

export default MemberBlock;
