import { createContext, useContext } from "react";
import { LifeEvent } from "core";

export interface ITimeline {
  events: LifeEvent[] | undefined;
}

export const TimelineContext = createContext<ITimeline>({
  events: [],
});
