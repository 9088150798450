import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NavIconLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;
  width: 28px;
  margin: 12px;

  font-size: 18px;
  text-decoration: none;

  opacity: 0.5;
  color: #394455;

  &:hover {
    opacity: 0.7;
    color: #2063cb;
  }

  &.active {
    opacity: 1;
    color: #2063cb;
  }
`;

export default NavIconLink;
