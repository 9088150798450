import React from "react";

import styled from "styled-components";
import { Pane } from "components/base/layout";

// stem protruding up from a member
// used to connect a node to its parents
const UpwardStem = styled(Pane)`
  position: absolute;
  bottom: 100%;
  z-index: 100;
  border-right: 1px solid ${(props) => props.theme["$slate-2"]};
  height: 11px;
`;

// stem protruding down from a member
// used to connect a single parent node to its children
const DownwardStem = styled(Pane)`
  position: absolute;
  top: 100%;
  z-index: 100;
  border-right: 1px solid ${(props) => props.theme["$slate-2"]};
  height: 11px;
`;

// vertical stem to the right of member
// used to connect member to its children
const DownwardRightStem = styled(Pane)`
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: 1px;
  width: 12px;
  z-index: 100;
  border-right: 1px solid ${(props) => props.theme["$slate-2"]};
  bottom: -12px;
`;

// stem protruding to the left of member
// used to connect member to its partner
const LeftStem = styled(Pane)`
  position: absolute;
  right: 100%;
  z-index: 100;
  border-top: 1px solid ${(props) => props.theme["$slate-2"]};
  width: 11px;
`;

// stem protruding to the right of member
// used to connect member to its partner
const RightStem = styled(Pane)`
  position: absolute;
  left: 100%;
  z-index: 100;
  border-top: 1px solid ${(props) => props.theme["$slate-2"]};
  width: 11px;
`;

const TopRightStem = styled(Pane)`
  position: absolute;
  top: -11px;
  right: -11px;
  width: calc(50% + 11px);
  border-top: 1px solid ${(props) => props.theme["$slate-2"]};
  content: "";
  z-index: 1017;
`;

const RightCover = styled(Pane)`
  position: absolute;
  top: -13px;
  right: -11px;
  width: calc(50% + 10px);
  border-top: 4px solid ${(props) => props.theme["$slate-1"]};
  content: "";
  z-index: 1017;
`;

const LeftCover = styled(Pane)`
  position: absolute;
  top: -13px;
  left: -11px;
  width: calc(50% + 11px);
  border-top: 4px solid ${(props) => props.theme["$slate-1"]};
  content: "";
  z-index: 1017;
`;

const MemberTreeStems = ({
  isFirstChild,
  isLastChild,
  hasChild,
  isFirstPartner,
  isLastPartner,
  isFirstParent,
  isLastParent,
  hasParents,
  hasLineage,
  continueConnectionOnLeft,
  continueConnectionOnRight,
}: {
  isFirstChild?: boolean;
  isLastChild?: boolean;
  hasChild?: boolean;
  isFirstPartner?: boolean;
  isLastPartner?: boolean;
  isFirstParent?: boolean;
  isLastParent?: boolean;
  hasParents?: boolean;
  hasLineage?: boolean;
  continueConnectionOnLeft?: boolean;
  continueConnectionOnRight?: boolean;
}) => (
  <>
    {hasLineage && hasParents && <UpwardStem />}
    {(isLastChild ||
      (isLastParent && isFirstPartner && !continueConnectionOnRight)) && (
      <RightCover />
    )}
    {isFirstChild && <LeftCover />}
    {hasChild && isFirstPartner && isLastPartner && <DownwardStem />}
    {hasChild && isFirstPartner && !isLastPartner && <DownwardRightStem />}
    {isFirstPartner !== undefined && !isFirstPartner && <LeftStem />}
    {isLastPartner !== undefined && !isLastPartner && <RightStem />}
    {isFirstParent && isFirstPartner && !isLastParent && <TopRightStem />}
  </>
);

export default MemberTreeStems;
