import { FunctionComponent } from "react";

export type GeoSuggestResultObj = {
  address: string;
  placeId?: string;
  gmaps?: any;
  lat?: number;
  lng?: number;
};

export type GeoSuggestProps = {
  onChange?: (change: GeoSuggestResultObj[]) => void;
  placeholder?: string;
  defaultValue?: GeoSuggestResultObj[];
};

const GeoSuggest: FunctionComponent<GeoSuggestProps> = require("antd-geosuggest-2")
  .default;

export default GeoSuggest;
