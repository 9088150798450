import { Grid } from "antd";
export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export const useIsMobile = (): boolean | undefined => {
  const screens: Partial<Record<ScreenSize, boolean>> = Grid.useBreakpoint();

  const isMobile: boolean | undefined = Object.keys(screens).length
    ? !screens.md
    : undefined;
  return isMobile;
};
