import { createContext, useContext } from "react";
import { MemberWithRelationships, MemberId } from "core";

export interface IMember {
  createMember: any;
  updateMember: any;
  createOrUpdateMember: any;
  deleteMember: any;
}

export interface IMembers {
  members: MemberWithRelationships[];
  membersMap: Map<MemberId, MemberWithRelationships>;
}

export interface ISelectedTimelineMember {
  selectedTimelineMember: MemberWithRelationships | undefined;
  setSelectedTimelineMemberId: (memberId: MemberId) => void;
}

export interface IUser {
  userMember: MemberWithRelationships | undefined;
}

export type ITree = IMember & IMembers & ISelectedTimelineMember & IUser;

export const TreeContext = createContext<ITree>({
  members: [],
  membersMap: new Map<MemberId, MemberWithRelationships>(),
  selectedTimelineMember: undefined,
  setSelectedTimelineMemberId: () => {},
  createMember: () => {},
  updateMember: () => {},
  createOrUpdateMember: () => {},
  deleteMember: () => {},
  userMember: undefined,
});

export const useMembersMap = (): Map<MemberId, MemberWithRelationships> => {
  const {
    membersMap,
  }: {
    membersMap: Map<MemberId, MemberWithRelationships>;
  } = useContext<ITree>(TreeContext);
  return membersMap;
};

export const useSelectedTimelineMember = (): MemberWithRelationships => {
  const {
    selectedTimelineMember,
  }: {
    selectedTimelineMember: MemberWithRelationships | undefined;
  } = useContext<ITree>(TreeContext);
  const member = selectedTimelineMember as MemberWithRelationships;
  return member;
};

export const useUserMember = (): MemberWithRelationships => {
  const {
    userMember,
  }: {
    userMember: MemberWithRelationships | undefined;
  } = useContext<ITree>(TreeContext);
  const member = userMember as MemberWithRelationships;
  return member;
};
