import React, { useContext } from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { UIProps } from "components/base/types";
import { MemberWithRelationships } from "core";

import { Row, Column } from "components/base/layout";
import Icon from "components/base/Icon";
import { TreeContext } from "pages/Tree/context";

const StyledMemberBlockActions = styled(Column)`
  position: absolute;
  top: -25px;
  left: -25px;
  right: -25px;
  bottom: -25px;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  &:hover {
    opacity: 1;
  }
`;

const StyledButton = styled(Row)`
  color: ${({ theme }) => transparentize(0.5, theme["$gray-7"])};

  &:hover {
    color: ${({ theme }) => theme["$blue-1"]};
  }
`;

const createPartnerToMember = (
  member: MemberWithRelationships
): Omit<MemberWithRelationships, "id"> => ({
  parents: [],
  partners: [member.id],
  children: [],
  siblings: [],
});

const createParentToMember = (
  member: MemberWithRelationships
): Omit<MemberWithRelationships, "id"> => ({
  parents: [],
  partners: member.parents,
  children: [...member.siblings, member.id],
  siblings: [],
});

const createChildToMember = (
  member: MemberWithRelationships
): Omit<MemberWithRelationships, "id"> => ({
  parents: [...member.partners, member.id],
  partners: [],
  children: [],
  siblings: [],
});

const Button = (props: UIProps) => (
  <StyledButton
    position="relative"
    cursor="pointer"
    backgroundColor="white"
    borderRadius={8}
    width={40}
    height={40}
    flexShrink={0}
    alignItems="center"
    color="$gray-7"
    justifyContent="center"
    boxShadow="0px 12px 34px rgba(71, 88, 114, 0.35)"
    {...props}
  />
);

const MemberBlockActions = ({
  member,
  setMemberToEdit,
  setPrimaryParentId,
  shouldShowLineageOption,
}: {
  member: MemberWithRelationships;
  setMemberToEdit: (n: Partial<MemberWithRelationships>) => void;
  setPrimaryParentId: (n: string) => void;
  shouldShowLineageOption?: boolean;
}) => {
  const { setSelectedTimelineMemberId } = useContext(TreeContext);

  return (
    <StyledMemberBlockActions>
      <Button
        title="Add parent"
        onClick={() =>
          setMemberToEdit && setMemberToEdit(createParentToMember(member))
        }
      >
        <Icon
          name="fa-user"
          fontSize={15}
          position="absolute"
          left={10}
          top={14}
        />
        <Icon
          name="fa-plus"
          position="absolute"
          fontSize={11}
          right={8}
          bottom={18}
          fontWeight={500}
        />
      </Button>
      <Row marginY={10}>
        <Button
          title="Edit"
          onClick={() => setMemberToEdit && setMemberToEdit(member)}
        >
          <Icon name="fa-pen" />
        </Button>

        <Button
          marginLeft={12}
          title="View timeline"
          onClick={() => setSelectedTimelineMemberId(member.id)}
        >
          <Icon name="fa-expand-alt" />
        </Button>

        <Button
          marginLeft={12}
          title="View tree"
          onClick={() => setPrimaryParentId && setPrimaryParentId(member.id)}
        >
          <Icon name="fa-sitemap" />
        </Button>

        {shouldShowLineageOption && (
          <Button
            marginLeft={12}
            title="Show lineage"
            onClick={() =>
              setPrimaryParentId && setPrimaryParentId(member.parents[0])
            }
          >
            <Icon name="fa-angle-double-up" />
          </Button>
        )}
        <Button
          marginLeft={12}
          title="Add spouse"
          onClick={() =>
            setMemberToEdit && setMemberToEdit(createPartnerToMember(member))
          }
        >
          <Icon
            name="fa-heart"
            fontSize={15}
            position="absolute"
            left={9}
            top={14}
          />
          <Icon
            name="fa-plus"
            position="absolute"
            fontSize={11}
            right={8}
            bottom={10}
            fontWeight={500}
          />
        </Button>
      </Row>
      <Button
        title="Add child"
        onClick={() =>
          setMemberToEdit && setMemberToEdit(createChildToMember(member))
        }
      >
        <Icon
          name="fa-child"
          fontSize={15}
          position="absolute"
          left={10}
          top={14}
        />
        <Icon
          name="fa-plus"
          position="absolute"
          fontSize={11}
          right={8}
          bottom={10}
          fontWeight={500}
        />
      </Button>
    </StyledMemberBlockActions>
  );
};

export default MemberBlockActions;
