import React, { useState } from "react";

import styled from "styled-components";

import { MemberWithRelationships, LifeEvent, LifeEventType } from "core";
import {
  getFormattedEventName,
  getFormattedEventDate,
  LifeEventIcon,
} from "utils/lifeEvent";

import { Popover, Modal } from "antd";
import { Row, Pane } from "components/base/layout";
import { Icon, Image } from "components/base";
import EditTimelineEventForm from "../EditTimelineEventForm";
import StoriesContainer from "./StoriesContainer";
import AddStoryForm from "./AddStoryForm";
import EventContext from "./context";

const StyledEventDetailsPane = styled(Row)`
  .onHover > * {
    opacity: 0;
    transition: 0.2s ease opacity;
  }

  &:hover .onHover > * {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
`;

const EventDetailsPane = ({
  event,
  member,
}: {
  event: LifeEvent;
  member: MemberWithRelationships;
}) => (
  <EventContext.Provider
    value={{
      event,
    }}
  >
    <StyledEventDetailsPane
      alignItems="flex-start"
      color="black"
      marginBottom={11}
      key={event.id}
    >
      <Row centerY marginTop={9}>
        <Pane borderRadius={10} width={4} height={4} backgroundColor="black" />
        <Pane position="absolute" marginLeft={19}>
          {getFormattedEventDate(event.timestamp)}
        </Pane>
        <Row centerX position="absolute" left={167} width={16} opacity={0.6}>
          <LifeEventIcon event={event} />
        </Row>
      </Row>
      <Pane marginLeft={186}>
        {getFormattedEventName(event)}

        {event.type === LifeEventType.Photo && (
          <Row maxWidth="100%" height={140} centerX marginY={6}>
            <Image
              maxHeight="100%"
              maxWidth="100%"
              src={event.details!.downloadUrl}
            />
          </Row>
        )}
      </Pane>
      <Row className="onHover" marginLeft="auto" centerY>
        {!event.isInferred && <EditEventButton event={event} />}
        <div>
          <AddStoryButton event={event} member={member} />
        </div>
      </Row>
    </StyledEventDetailsPane>
    {event.stories && (
      <StoriesContainer member={member} event={event} stories={event.stories} />
    )}
  </EventContext.Provider>
);

const AddStoryButton = ({
  event,
  member,
}: {
  event: LifeEvent;
  member: MemberWithRelationships;
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <Icon
        marginLeft={8}
        cursor="pointer"
        name="fa-comment"
        title="Add story"
        onClick={() => setIsVisible(!isVisible)}
      />
      <Modal
        title="New story"
        visible={isVisible}
        zIndex={2001}
        footer={null}
        bodyStyle={{ borderRadius: 8 }}
        onCancel={() => setIsVisible(false)}
      >
        {isVisible && (
          <AddStoryForm event={event} close={() => setIsVisible(false)} />
        )}
      </Modal>
    </>
  );
};

const EditEventButton = ({ event }: { event: LifeEvent }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      title="Edit timeline event"
      visible={isVisible}
      onVisibleChange={setIsVisible}
      content={
        <EditTimelineEventForm
          lifeEvent={event}
          isVisible={isVisible}
          closePopover={() => setIsVisible(false)}
        />
      }
      overlayStyle={{ zIndex: 2001 }}
    >
      <div>
        <Icon cursor="pointer" name="fa-pen" title="Edit event" />
      </div>
    </Popover>
  );
};

export default EventDetailsPane;
