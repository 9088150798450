import React from "react";
import { Column } from "components/base/layout";
import { UIProps } from "components/base/types";
import { useIsMobile } from "utils/hooks";

const ContentContainer = (props: UIProps) => {
  const isMobile = useIsMobile();
  return (
    <Column
      flexGrow={1}
      backgroundColor="#F8F9FC"
      paddingLeft={24}
      paddingTop={isMobile ? 20 : 40}
      alignItems={isMobile ? "center" : "flex-start"}
      overflow="auto"
      height="100vh"
      {...props}
    />
  );
};

export default ContentContainer;
