import React, { useEffect } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";

import Tree from "pages/Tree";
import Directory from "pages/Directory";
import Login from "pages/Login";
import CreateFamily from "pages/CreateFamily";
import RedirectToLogin from "./RedirectToLogin";
import RedirectToFamilyId from "./RedirectToFamilyId";
import CheckIfFamilyExistsThenRedirect from "./CheckIfFamilyExistsThenRedirect";

const PageContent = ({
  userId,
  setUserId,
}: {
  userId: string | null;
  setUserId: (userId: string) => void;
}) => {
  return (
    <Switch>
      <Route path="/:familyId/login">
        {!userId && <Login setUserId={setUserId} />}
        {!!userId && <RedirectToFamilyId />}
      </Route>
      {!userId && (
        <>
          <Route path="/:familyId/create">
            <CreateFamily setUserId={setUserId} />
          </Route>
          <Route path="/:familyId">
            <CheckIfFamilyExistsThenRedirect />
          </Route>
        </>
      )}
      <Route path="/:familyId/create">
        <RedirectToFamilyId />
      </Route>

      <Route path="/:familyId/people" component={Directory} />
      <Route path="/:familyId/settings" />

      <Route path="/:familyId/:baseMemberId/:timelineMemberId">
        <Tree userId={userId as string} />
      </Route>
      <Route path="/:familyId/:baseMemberId">
        <Tree userId={userId as string} />
      </Route>
      <Route path="/:familyId/">
        <Tree userId={userId as string} />
      </Route>
    </Switch>
  );
};

export default PageContent;
