import React from "react";
import { useIsMobile } from "utils/hooks";
import { UIProps } from "components/base/types";

import { Row, Column } from "components/base/layout";
import Icon from "components/base/Icon";
import NavIconLink from "components/NavIconLink";

const NavigationBar = () => {
  const isMobile = useIsMobile();

  return (
    <LayoutContainer isMobile={isMobile}>
      <img src="/logo.svg" width={32} alt="logo" />

      <NavLinkLayoutContainer isMobile={isMobile}>
        <NavIconLink to="/">
          <Icon name="fa-sitemap" />
        </NavIconLink>
        <NavIconLink to="/people">
          <Icon name="fa-users" />
        </NavIconLink>
        <NavIconLink to="/settings">
          <Icon name="fa-cog" />
        </NavIconLink>
      </NavLinkLayoutContainer>
    </LayoutContainer>
  );
};

const LayoutContainer = ({
  isMobile,
  ...props
}: {
  isMobile: boolean | undefined;
} & UIProps) =>
  isMobile ? (
    <Row centerY paddingY={8} paddingX={22} flexShrink={0} {...props} />
  ) : (
    <Column centerX paddingY={46} paddingX={10} flexShrink={0} {...props} />
  );

const NavLinkLayoutContainer = ({
  isMobile,
  ...props
}: {
  isMobile: boolean | undefined;
} & UIProps) =>
  isMobile ? (
    <Row grow center {...props} />
  ) : (
    <Column grow center paddingBottom={32} {...props} />
  );

export default NavigationBar;
