import React from "react";
import styled from "styled-components";
import { FamilyUnit } from "../../types";
import { Pane, Row } from "components/base/layout";
import { UIProps } from "components/base/types";
import { MemberWithRelationships } from "core";
import MemberBlock from "../MemberBlock";

const TopConnectorStem = styled(Pane)`
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  z-index: 1016;
  border-top: 1px solid ${(props) => props.theme["$slate-2"]};
`;

const LeftCover = styled(Pane)`
  position: absolute;
  top: -2px;
  left: -10px;
  right: 50%;
  z-index: 1016;
  border-top: 3px solid #f8f9fc;
`;

const RightCover = styled(Pane)`
  position: absolute;
  top: -2px;
  left: calc(50% + 1px);
  right: -10px;
  z-index: 1017;
  border-top: 4px solid #f8f9fc;
`;

const sortFamilyUnitsOldestToYoungest = (
  familyUnitA: FamilyUnit,
  familyUnitB: FamilyUnit
): number => {
  if (
    familyUnitA.parents[0].birthTimestamp &&
    familyUnitB.parents[0].birthTimestamp
  ) {
    if (
      familyUnitA.parents[0].birthTimestamp <
      familyUnitB.parents[0].birthTimestamp
    ) {
      return -1;
    }
    if (
      familyUnitA.parents[0].birthTimestamp >
      familyUnitB.parents[0].birthTimestamp
    ) {
      return 1;
    }
    return 0;
  }
  if (familyUnitA.parents[0].birthTimestamp) {
    return 1;
  }
  if (familyUnitB.parents[0].birthTimestamp) {
    return -1;
  }
  return 0;
};

const FamilyUnitBlock = ({
  familyUnit,
  setPrimaryParentId,
  setMemberToEdit,
  containerProps,
  isFinalAncestor,
  isFirstChild,
  isLastChild,
  connectToSiblingOnLeftInGeneration,
  connectToSiblingOnRightInGeneration,
  connectToSiblingsInGeneration,
  setBaseMemberBlockRef,
  ...props
}: {
  familyUnit: FamilyUnit | null;
  setPrimaryParentId: (n: string) => void;
  setMemberToEdit: (n: Partial<MemberWithRelationships>) => void;
  isFirstChild?: boolean;
  isLastChild?: boolean;
  isFinalAncestor?: boolean;
  connectToSiblingOnLeftInGeneration?: number;
  connectToSiblingOnRightInGeneration?: number;
  connectToSiblingsInGeneration?: number;
  setBaseMemberBlockRef?: any;
} & UIProps) => {
  if (!familyUnit) {
    return <></>;
  }

  const doesGenerationHaveConnector =
    connectToSiblingsInGeneration === familyUnit.generation;

  const doesGenerationHaveLeftConnector =
    connectToSiblingOnLeftInGeneration === familyUnit.generation;
  const doesGenerationHaveRightConnector =
    connectToSiblingOnRightInGeneration === familyUnit.generation;

  return (
    <Pane borderRadius={8} paddingX={10} width="100%" {...props}>
      <Row justifyContent="center" position="relative">
        {doesGenerationHaveConnector && <TopConnectorStem />}
        {isFirstChild &&
          (!isLastChild || doesGenerationHaveConnector) &&
          !doesGenerationHaveLeftConnector && <LeftCover />}
        {isLastChild &&
          (!isFirstChild || doesGenerationHaveConnector) &&
          !doesGenerationHaveRightConnector && <RightCover />}
        {familyUnit.parents.map(
          (
            parent: MemberWithRelationships,
            i: number,
            parents: MemberWithRelationships[]
          ) => (
            <MemberBlock
              member={parent}
              key={parent.id}
              hasChild={familyUnit.children.length > 0}
              isFirstPartner={i === 0}
              setBaseMemberBlockRef={
                setBaseMemberBlockRef && doesGenerationHaveConnector && i === 0
                  ? setBaseMemberBlockRef
                  : null
              }
              isLastPartner={i === parents.length - 1}
              isFirstParent={isFirstChild}
              isLastParent={isLastChild}
              isFirstChild={
                i === parents.length - 1 && doesGenerationHaveConnector
              }
              isLastChild={i === 0 && doesGenerationHaveConnector}
              hasLineage={
                (!isFinalAncestor &&
                  parent.id === familyUnit.primaryParentId) ||
                doesGenerationHaveConnector
              }
              setPrimaryParentId={setPrimaryParentId}
              setMemberToEdit={setMemberToEdit}
              continueConnectionOnRight={doesGenerationHaveRightConnector}
              continueConnectionOnLeft={doesGenerationHaveLeftConnector}
            />
          )
        )}
      </Row>
      <Row
        margin="auto"
        alignItems="flex-start"
        boxShadow={
          familyUnit.children.length > 1 ? "inset 0 1px 0 0 #C9C6E6" : "none"
        }
      >
        {[...familyUnit.children]
          .sort(sortFamilyUnitsOldestToYoungest)
          .map((child: FamilyUnit, i: number, children: FamilyUnit[]) => (
            <FamilyUnitBlock
              familyUnit={child}
              key={child.primaryParentId}
              isFirstChild={i === 0}
              isLastChild={i === children.length - 1}
              setPrimaryParentId={setPrimaryParentId}
              setMemberToEdit={setMemberToEdit}
              connectToSiblingOnLeftInGeneration={
                connectToSiblingOnLeftInGeneration
              }
              connectToSiblingOnRightInGeneration={
                connectToSiblingOnRightInGeneration
              }
            />
          ))}
      </Row>
    </Pane>
  );
};

export default FamilyUnitBlock;
