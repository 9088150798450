import React from "react";

import { Button as AntdButton } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";

type AntdButtonProps = React.ComponentProps<typeof AntdButton>;

export const Button = (props: AntdButtonProps & UIProps) => (
  <Box is={AntdButton} {...props} />
);

export default Button;
