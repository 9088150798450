import React, { useEffect } from "react";

import { keysOfEnum } from "utils/common";

import { Button, Radio, Progress } from "antd";
import { Row, Pane } from "components/base/layout";
import Icon from "components/base/Icon";

import DurationIndicator from "./DurationIndicator";
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player-guru";

enum PlaybackRates {
  x100 = 1,
  x120 = 1.2,
  x140 = 1.4,
  x160 = 1.6,
}

const Player = ({ url }: any) => {
  const {
    togglePlayPause,
    ready,
    loading,
    playing,
    stop,
    duration,
    seek,
    rate,
  } = useAudioPlayer({
    src: url,
    html5: true,
    autoplay: true,
  });
  const { position } = useAudioPosition({ highRefreshRate: true });

  const startTime = 0;
  const endTime = duration;

  useEffect(() => {
    if (ready && !loading) {
      seek(startTime);
    }
  }, [ready, loading, startTime, seek]);

  useEffect(() => {
    if (position > endTime) {
      stop();
      seek(startTime);
    }
  }, [position, startTime, endTime, seek, stop]);

  if (!ready && !loading) return <div>No audio to play</div>;
  if (loading) return <div></div>;

  const timeElapsed = position - startTime;

  const percent = Math.round((timeElapsed / duration) * 100 || 0);

  const currentRate = rate();

  const shouldShowControls = playing || timeElapsed > 0;

  return (
    <Pane>
      <Row>
        <Button
          onClick={togglePlayPause}
          icon={
            <Icon
              fontSize="12px!important"
              name={playing ? "fa-pause" : "fa-play"}
            />
          }
        />
        {shouldShowControls && (
          <>
            <Button
              onClick={() => {
                stop();
                seek(startTime);
              }}
              style={{ marginLeft: 8 }}
              icon={<Icon fontSize="12px!important" name="fa-stop" />}
            />
            <Radio.Group
              style={{ marginLeft: "auto" }}
              onChange={(selected: any) => rate(selected.target.value)}
              value={currentRate}
            >
              {keysOfEnum(PlaybackRates).map((eachValue: string) => (
                <Radio.Button
                  key={eachValue}
                  value={Number(eachValue)}
                  style={{ fontSize: 12, paddingLeft: 12, paddingRight: 12 }}
                >
                  {eachValue + "x"}
                </Radio.Button>
              ))}
            </Radio.Group>
          </>
        )}
      </Row>
      {shouldShowControls && (
        <Row marginTop={3} centerY>
          <Pane position="relative" flexGrow={1}>
            <Progress percent={percent} showInfo={false} />
            <Row position="absolute" top={0} left={0} right={0} bottom={0}>
              {Array.from(Array(Math.floor(duration))).map(
                (none: any, i: number) => (
                  <Pane
                    flexGrow={1}
                    cursor="pointer"
                    onClick={() => seek(startTime + i)}
                  ></Pane>
                )
              )}
            </Row>
          </Pane>
          <DurationIndicator duration={duration} timeElapsed={timeElapsed} />
        </Row>
      )}
    </Pane>
  );
};

export default Player;
