import React from "react";
import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";

export const Icon = ({
  name,
  type,
  fixedWidth,
  ...props
}: { name: string; type?: string; fixedWidth?: boolean } & UIProps) => (
  <Pane
    is="i"
    className={`${type || "fal"} ${fixedWidth ? "fa-fw" : ""} ${name}`}
    {...props}
  />
);

export default Icon;
