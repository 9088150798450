import React, { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import { SortableElement } from "react-sortable-hoc";
import styled from "styled-components";

import { Story, FileType } from "core";
import { UIProps } from "components/base/types";

import { Pane, Row } from "components/base/layout";

import { Popover } from "antd";
import { AudioPlayerProvider } from "react-use-audio-player-guru";
import { Icon, Button, Image } from "components/base";
import Player from "./Player";
import EditStoryForm from "./EditStoryForm";

const EditStoryButton = ({ story }: { story: Story }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <Popover
      trigger="click"
      title="Edit story"
      visible={isVisible}
      onVisibleChange={setIsVisible}
      content={
        <EditStoryForm
          isVisible={isVisible}
          story={story}
          closePopover={() => setIsVisible(false)}
        />
      }
      overlayStyle={{ zIndex: 2001 }}
    >
      <div>
        <Icon cursor="pointer" name="fa-pen" title="Edit story" />
      </div>
    </Popover>
  );
};

const StyledDisplayStoryPane = styled(Pane)`
  margin-bottom: 12px;

  .onHover {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover .onHover {
    opacity: 1;
  }
`;

const DisplayStoryPane = SortableElement(({ story }: { story: Story }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!story.url) {
    return <></>;
  }

  return (
    <StyledDisplayStoryPane>
      <Pane marginLeft={192} width="calc(100% - 192px)">
        {story.type === FileType.Image ? (
          <>
            <Row fontSize={13} marginBottom={6}>
              {story.humanName}
              <Row marginLeft="auto" className="onHover">
                <EditStoryButton story={story} />
              </Row>
            </Row>
            <Image
              cursor="pointer"
              src={story.url}
              height={isOpen ? "unset" : 100}
              maxWidth="100%"
              onClick={(e: any) => {
                setIsOpen(!isOpen);
              }}
            />
          </>
        ) : (
          <>
            {isOpen ? (
              <>
                <Row fontSize={13} marginBottom={6}>
                  {story.humanName}

                  <Row marginLeft="auto" className="onHover">
                    <EditStoryButton story={story} />
                  </Row>
                </Row>
                <AudioPlayerProvider>
                  <Player url={story.url} />
                </AudioPlayerProvider>
              </>
            ) : (
              <Button
                style={{
                  borderRadius: 8,
                  fontSize: 13,
                  height: "auto",
                }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Icon name="fa-volume" marginRight={8} />
                {story.humanName}
              </Button>
            )}
          </>
        )}
      </Pane>
    </StyledDisplayStoryPane>
  );
});

export default DisplayStoryPane;
