import React, { useState, useEffect } from "react";
import * as firebase from "firebase";
import { Progress } from "antd";

const FirebaseUploadBar = ({
  uploadTask,
}: {
  uploadTask: firebase.storage.UploadTask;
}) => {
  const [percent, setPercent] = useState<number>(0);
  useEffect(() => {
    uploadTask?.on(
      "state_changed",
      (snapshot: firebase.storage.UploadTaskSnapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPercent(progress);
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }, [uploadTask]);
  return <Progress status="active" percent={percent} showInfo={false} />;
};

export default FirebaseUploadBar;
