import React, { useState, useEffect } from "react";
import { Upload, message, Progress } from "antd";

import * as firebase from "firebase/app";
import { MemberWithRelationships, LifeEvent, FileType } from "core";
import { useSelectedTimelineMember } from "pages/Tree/context";
import { getUniqueFileNameParts, getFileType } from "utils/common";

import { Icon } from "components/base";
import { Pane } from "components/base/layout";
import FirebaseUploadBar from "components/FirebaseUploadBar";
import NameFileModal from "./NameFileModal";

type FileWithHumanName = {
  humanName?: string;
} & File;

const AddStoryForm = ({
  event,
  close,
}: {
  event: LifeEvent;
  close: () => void;
}) => {
  const [transformingFile, setTransformingFile] = useState<File | boolean>();
  const member: MemberWithRelationships = useSelectedTimelineMember();
  const [resolveName, setResolveName] = useState(() => (props: any) => {});
  const [name, setName] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [uploadTask, setUploadTask] = useState<firebase.storage.UploadTask>();

  useEffect(() => {
    if (name) {
      resolveName(name);
    }
  }, [name]);

  const saveName = (
    fileName: string,
    humanName: string,
    url: string,
    type: FileType
  ) => {
    const ref = firebase
      .database()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(member.id)
      .child("events")
      .child(event.id)
      .child("stories")
      .push();

    ref.set({ humanName, fileName, url, id: ref.key, eventId: event.id, type });
  };

  const customRequest = async ({ onError, onSuccess, file }: any) => {
    console.log("customRequest", file);
    const { fileName, extension } = getUniqueFileNameParts(file.name);
    const ref = firebase
      .storage()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(member.id)
      .child("stories")
      .child(fileName);

    const fileType = getFileType(extension);
    let contentType = "";
    if (fileType === FileType.Image) {
      contentType = "image/jpeg";
    } else if (fileType === FileType.Audio) {
      contentType = "audio/mpeg";
    }

    const metadata = {
      cacheControl: "public,max-age=72000",
      contentType,
    };
    try {
      const uploadTask = ref.put(file, metadata);
      setUploadTask(uploadTask);

      const audio = await uploadTask;
      const downloadUrl = await ref.getDownloadURL();
      saveName(fileName, file.humanName, downloadUrl, fileType);
      onSuccess(null, audio);
    } catch (e) {
      onError(e);
    }
  };

  const transformFile = async (file: File): Promise<FileWithHumanName> => {
    setIsProcessing(true);
    setTransformingFile(file);

    const humanName: string = await new Promise((resolve, reject) => {
      setResolveName(() => (name: string) => resolve(name));
    });

    setTransformingFile(false);

    const fileWithHumanName: FileWithHumanName = file;
    fileWithHumanName.humanName = humanName;

    console.log("fileWithHumanName", fileWithHumanName, file);

    return fileWithHumanName;
  };

  const props: React.ComponentProps<typeof Upload.Dragger> = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest,
    transformFile,
    onChange(info: any) {
      const { status } = info.file;
      console.log(info);
      console.log(info.file.percent, status);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setIsProcessing(false);
        close();
        message.success(`${info.file.humanName} saved.`);
      } else if (status === "error") {
        setIsProcessing(false);
        close();
        message.error(`${info.file.humanName} upload failed.`);
      }
    },
  };

  return (
    <Pane>
      <Upload.Dragger {...props}>
        <Pane textAlign="center">
          <Icon name="fa-inbox" fontSize={30} />
        </Pane>
        <Pane
          textAlign="center"
          fontSize={13}
          paddingY={8}
          paddingX={20}
          opacity={0.7}
        >
          Click or drag file to this area to upload
        </Pane>
      </Upload.Dragger>
      {isProcessing && uploadTask && (
        <FirebaseUploadBar uploadTask={uploadTask} />
      )}
      <NameFileModal transformingFile={transformingFile} setName={setName} />
    </Pane>
  );
};

export default AddStoryForm;
