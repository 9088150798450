import React from "react";
import { Privacy } from "core";
import { UIProps } from "components/base/types";
import Icon from "components/base/Icon";

export const PrivacyIcon = ({
  privacy,
  ...props
}: { privacy: Privacy } & UIProps) => {
  switch (privacy) {
    case Privacy.Public:
      return <Icon name="fa-globe" {...props} />;
    case Privacy.ExtendedFamily:
      return <Icon name="fa-users" {...props} />;
    case Privacy.FamilyUnit:
      return <Icon name="fa-sitemap" {...props} />;
    case Privacy.Me:
      return <Icon name="fa-user" {...props} />;
    case Privacy.Custom:
      return <Icon name="fa-cog" {...props} />;
  }
  return <></>;
};
