import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, DatePicker, Select, Checkbox } from "antd";
import Radio, { RadioChangeEvent } from "antd/es/radio";
import moment from "moment";

import {
  MemberWithRelationships,
  Member,
  MemberId,
  Relationships,
  MemberFamilyStatus,
} from "core";
import { getMemberName } from "utils/member";

import { Pane, Row, Column } from "components/base/layout";
import GeoSuggest from "components/base/GeoSuggest";
import { Field, SubmitButton, DeleteButton, Label } from "components/base/Form";

const EditMemberPanel = ({
  allMembers,
  member,
  closeEditMemberPanel,
  createOrUpdateMember,
  deleteMember,
  userId,
}: {
  allMembers: MemberWithRelationships[];
  member: Partial<MemberWithRelationships>;
  closeEditMemberPanel: () => void;
  createOrUpdateMember?: (
    editedMember: Partial<Member>,
    relationships: Relationships
  ) => void;
  deleteMember?: (n: string) => void;
  userId?: MemberId;
}) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = async (form: any) => {
    const editedMember: Partial<Member> = {
      id: member.id,
      firstName: form.firstName,
      middleName: form.middleName,
      lastName: form.lastName,
      nicknames: form.nicknames,
      gender: form.gender,
      location: form.location ? form.location[0].address : undefined,
      birthPlace: form.birthPlace ? form.birthPlace[0].address : undefined,
      deathPlace: form.deathPlace ? form.deathPlace[0].address : undefined,
      defaultFamilyStatus: form.isCrossedOut
        ? MemberFamilyStatus.CrossedOut
        : MemberFamilyStatus.Active,
    };

    if (userId) {
      editedMember.createdBy = userId;
    }

    if (form.birthdate) {
      editedMember.birthDay = parseInt(form.birthdate.format("D"));
      editedMember.birthMonth = parseInt(form.birthdate.format("M"));
      editedMember.birthYear = parseInt(form.birthdate.format("YYYY"));
      editedMember.birthTimestamp = form.birthdate.format();
    }
    if (form.deathdate) {
      editedMember.deathDay = parseInt(form.deathdate.format("D"));
      editedMember.deathMonth = parseInt(form.deathdate.format("M"));
      editedMember.deathYear = parseInt(form.deathdate.format("YYYY"));
      editedMember.deathTimestamp = form.deathdate.format();
    }

    const relationships: Relationships = {
      parents: form.parents || [],
      partners: member.partners || [],
      children: form.children || [],
      siblings: member.siblings || [],
    };

    createOrUpdateMember && createOrUpdateMember(editedMember, relationships);
    closeEditMemberPanel();
  };

  const memberId: string = member.id || "";
  const partners: string[] = member.partners || [];

  const primaryPartner =
    partners.length > 0 &&
    allMembers.find(
      (eachMember: MemberWithRelationships) => eachMember.id === partners[0]
    );

  return (
    <Pane
      is="form"
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      justifyContent="center"
    >
      <Column paddingBottom={5}>
        <Field>
          <Row>
            <Controller
              as={Input}
              name="firstName"
              defaultValue={member.firstName}
              placeholder="First Name"
              control={control}
            />
            <Controller
              as={<Input style={{ marginLeft: 8, width: "60%" }} />}
              name="middleName"
              defaultValue={member.middleName}
              placeholder="Middle"
              control={control}
            />
          </Row>
        </Field>
        <Field>
          <Controller
            as={Input}
            name="lastName"
            defaultValue={member.lastName}
            placeholder="Last Name"
            control={control}
          />
        </Field>
        <Field>
          <Controller
            as={Input}
            name="nicknames"
            defaultValue={member.nicknames}
            placeholder="Nicknames"
            control={control}
          />
        </Field>
        <Field>
          <Controller
            as={
              <GeoSuggest
                placeholder="Location"
                defaultValue={
                  member.location ? [{ address: member.location }] : undefined
                }
              />
            }
            name="location"
            control={control}
          />
        </Field>
        <Field>
          <Controller
            render={({ onChange, ...props }) => (
              <Radio.Group
                {...props}
                onChange={(e: RadioChangeEvent) => onChange(e.target.value)}
                style={{ width: "100%", display: "flex" }}
              >
                <Radio.Button value="female" style={{ width: "100%" }}>
                  Female
                </Radio.Button>
                <Radio.Button value="male" style={{ width: "100%" }}>
                  Male
                </Radio.Button>
                <Radio.Button value="other" style={{ width: "100%" }}>
                  Other
                </Radio.Button>
              </Radio.Group>
            )}
            name="gender"
            defaultValue={member.gender}
            control={control}
          />
        </Field>
        <Field>
          <Controller
            as={
              <DatePicker
                style={{ width: "100%" }}
                popupStyle={{ zIndex: 3000 }}
              />
            }
            name="birthdate"
            defaultValue={
              member.birthTimestamp ? moment(member.birthTimestamp) : ""
            }
            placeholder="Birth Date"
            defaultPickerValue={moment().subtract(20, "years")}
            control={control}
          />
        </Field>
        <Field>
          <Controller
            as={
              <GeoSuggest
                placeholder="Birthplace"
                defaultValue={
                  member.birthPlace
                    ? [{ address: member.birthPlace }]
                    : undefined
                }
              />
            }
            name="birthPlace"
            control={control}
          />
        </Field>

        {!!allMembers.length && (
          <>
            <Field>
              <Controller
                as={
                  <DatePicker
                    style={{ width: "100%" }}
                    popupStyle={{ zIndex: 3000 }}
                  />
                }
                name="deathdate"
                defaultValue={
                  member.deathTimestamp ? moment(member.deathTimestamp) : ""
                }
                placeholder="Death Date"
                defaultPickerValue={moment().subtract(20, "years")}
                control={control}
              />
            </Field>
            <Field>
              <Controller
                as={
                  <GeoSuggest
                    placeholder="Deathplace"
                    defaultValue={
                      member.deathPlace
                        ? [{ address: member.deathPlace }]
                        : undefined
                    }
                  />
                }
                name="deathPlace"
                control={control}
              />
            </Field>
            <Field>
              <Label>Child of</Label>
              <Controller
                name="parents"
                as={
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 3000 }}
                    disabled={!!member.id}
                  >
                    {allMembers.map((eachMember: MemberWithRelationships) => (
                      <Select.Option key={eachMember.id} value={eachMember.id}>
                        {getMemberName(eachMember)}
                      </Select.Option>
                    ))}
                  </Select>
                }
                placeholder="None"
                defaultValue={member.parents || []}
                control={control}
              />
            </Field>

            <Field>
              <Label>Parent to</Label>
              <Controller
                name="children"
                as={
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 3000 }}
                    disabled={!!member.id}
                  >
                    {allMembers.map((eachMember: MemberWithRelationships) => (
                      <Select.Option key={eachMember.id} value={eachMember.id}>
                        {getMemberName(eachMember)}
                      </Select.Option>
                    ))}
                  </Select>
                }
                placeholder="None"
                defaultValue={member.children || []}
                control={control}
              />
            </Field>

            {primaryPartner && (
              <Field>
                <Label>Spouse to {getMemberName(primaryPartner)}</Label>
              </Field>
            )}

            <Field>
              <Controller
                render={({ onChange, value }: any) => {
                  const checkedProp =
                    value === undefined
                      ? {}
                      : {
                          checked: value,
                        };

                  return (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      {...checkedProp}
                      style={{ fontSize: 12 }}
                    >
                      Crossed out?
                    </Checkbox>
                  );
                }}
                name="isCrossedOut"
                control={control}
              />
            </Field>
          </>
        )}

        <Row justifyContent="space-between" marginTop={20}>
          <SubmitButton>Submit</SubmitButton>

          {deleteMember && (
            <DeleteButton
              marginLeft={12}
              onClick={() =>
                window.confirm(
                  `Delete ${getMemberName(member as Member) || "this person"}?`
                ) && deleteMember(memberId)
              }
            >
              Delete
            </DeleteButton>
          )}
        </Row>
      </Column>
    </Pane>
  );
};

export default EditMemberPanel;
