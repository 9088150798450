import React from "react";
import * as firebase from "firebase/app";
import { Pane, Row, Column } from "components/base/layout";
import { useForm, Controller } from "react-hook-form";
import { Select, InputNumber, Checkbox, Radio, Divider } from "antd";
import {
  LifeEvent,
  LifeEventType,
  LifeEventMeta,
  LifeEventMetaData,
  MemberWithRelationships,
  Privacy,
  TimeDetails,
  Months,
} from "core";
import { Field, SubmitButton, DeleteButton } from "components/base/Form";
import { useSelectedTimelineMember, useUserMember } from "pages/Tree/context";
import { keysOfEnum, pruneUndefinedValuesFromObject } from "utils/common";
import {
  LifeEventIcon,
  PrivacyIcon,
  getFormattedEventName,
  getFormattedEventDate,
} from "utils/lifeEvent";
import LifeEventDetailFormFields from "./LifeEventDetailFormFields";

export interface Form {
  type: LifeEventType;
  year: number;
  month: number | undefined;
  day: number | undefined;
  isApproximate: boolean | undefined;
  privacy: Privacy | undefined;
  details: any;
}

const lifeEventToFormState = (event: LifeEvent): Form => ({
  type: event.type,
  year: event.timestamp.components.year,
  month: event.timestamp.components.month,
  day: event.timestamp.components.day,
  isApproximate: event.timestamp.isApproximate,
  privacy: event.privacy,
  details: event.details,
});

const EditTimelineEventForm = ({
  closePopover,
  isVisible,
  lifeEvent,
}: {
  closePopover: any;
  isVisible: boolean;
  lifeEvent?: LifeEvent;
}) => {
  const member: MemberWithRelationships = useSelectedTimelineMember();
  const userMember: MemberWithRelationships = useUserMember();

  const { handleSubmit, control, watch, register } = useForm<Form>({
    defaultValues: lifeEvent ? lifeEventToFormState(lifeEvent) : {},
  });

  const onSubmit = async (form: Form) => {
    const { type, year, month, day, isApproximate, privacy, details } = form;

    const timestamp: TimeDetails = {
      components: {
        year,
      },
      isApproximate: !!isApproximate,
      raw: `${year}-01-01`,
    };

    if (month) {
      timestamp!.components!.month = month;
      timestamp.raw = `${year}-${month}-01`;

      if (day) {
        timestamp!.components!.day = day;
        timestamp.raw = `${year}-${month}-${day}`;
      }
    }

    const eventsRef = firebase
      .database()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(member.id)
      .child("events");

    if (lifeEvent) {
      const ref = eventsRef.child(lifeEvent.id);

      const editedLifeEvent: LifeEvent = {
        ...lifeEvent,
        type: type || lifeEvent.type,
        privacy,
        timestamp,
        details,
      };

      ref.update(pruneUndefinedValuesFromObject(editedLifeEvent));
    } else {
      const ref = eventsRef.push();

      if (ref.key) {
        const editedLifeEvent: Partial<LifeEvent> = {
          type,
          privacy,
          timestamp,
          details,
          memberId: member.id,
          id: ref.key,
        };

        ref.set(pruneUndefinedValuesFromObject(editedLifeEvent));
      }
    }

    closePopover();
  };

  const {
    type,
    year,
    month,
    day,
    isApproximate,
    details,
  }: Partial<Form> = watch();

  const timestamp = {
    components: {
      year,
      month,
      day,
    },
    isApproximate,
    raw: "",
  };

  const eventPreview = {
    type,
    timestamp,
    details,
  };

  return (
    <Pane
      is="form"
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      justifyContent="center"
      maxWidth={440}
    >
      <input
        ref={register}
        type="hidden"
        name="createdBy"
        value={userMember.id}
      />
      <Column paddingBottom={5}>
        <Field>
          <Controller
            render={({ onChange, value }: any) => (
              <Radio.Group
                onChange={(selected: any) => onChange(selected.target.value)}
                value={value}
                style={{ marginRight: -6 }}
              >
                {Object.values(LifeEventMeta)
                  .filter(
                    (meta: LifeEventMetaData) => !meta.isHiddenFromAddMenus
                  )
                  .map((meta: LifeEventMetaData) => (
                    <Radio.Button
                      key={meta.id}
                      value={meta.id}
                      style={{
                        height: 75,
                        width: 105,
                        marginRight: 6,
                        marginBottom: 6,
                      }}
                    >
                      <Column height="100%" center>
                        <LifeEventIcon marginTop={8} type={meta.id} />
                        <Pane fontSize={13}>{meta.id}</Pane>
                      </Column>
                    </Radio.Button>
                  ))}
              </Radio.Group>
            )}
            name="type"
            control={control}
          />
        </Field>
        {type && (
          <LifeEventDetailFormFields
            lifeEventType={type}
            control={control}
            register={register}
            watch={watch}
          />
        )}

        <Field>
          <Row marginBottom={6} centerY>
            <Pane marginRight={6}>
              <Controller
                as={InputNumber}
                name="year"
                placeholder="Year"
                control={control}
                min={1000}
                max={new Date().getFullYear()}
              />
            </Pane>
            <Pane marginRight={6}>
              <Controller
                as={
                  <Select dropdownStyle={{ zIndex: 3000 }}>
                    {keysOfEnum(Months).map((eachMonth: any) => (
                      <Select.Option key={eachMonth} value={Number(eachMonth)}>
                        {Months[eachMonth]}
                      </Select.Option>
                    ))}
                  </Select>
                }
                name="month"
                placeholder="Month"
                control={control}
              />
            </Pane>
            <Controller
              as={InputNumber}
              min={1}
              max={31}
              name="day"
              placeholder="Day"
              control={control}
            />
            <Controller
              render={({ onChange, value }: any) => {
                const checkedProp =
                  value === undefined
                    ? {}
                    : {
                        checked: value,
                      };

                return (
                  <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    {...checkedProp}
                    style={{ marginLeft: 8 }}
                  >
                    Approximate
                  </Checkbox>
                );
              }}
              name="isApproximate"
              control={control}
            />
          </Row>
        </Field>
        <Field>
          <Controller
            as={
              <Select
                style={{ width: "fit-content" }}
                optionLabelProp="label"
                dropdownMatchSelectWidth={200}
              >
                {Object.keys(Privacy).map((eachPrivacyType: any) => (
                  <Select.Option
                    key={eachPrivacyType}
                    value={eachPrivacyType}
                    label={<PrivacyIcon privacy={eachPrivacyType} fixedWidth />}
                  >
                    <PrivacyIcon
                      privacy={eachPrivacyType}
                      fixedWidth
                      marginRight={6}
                    />{" "}
                    {eachPrivacyType}
                  </Select.Option>
                ))}
              </Select>
            }
            name="privacy"
            defaultValue={Privacy.Public}
            placeholder="Privacy"
            control={control}
          />
        </Field>

        <Divider />

        {timestamp.components.year && (
          <Pane>
            <EventPane event={eventPreview} />
          </Pane>
        )}

        <Row>
          <SubmitButton marginLeft="auto">Submit</SubmitButton>
          {lifeEvent && (
            <DeleteButton
              marginLeft={12}
              onClick={() =>
                deleteEvent &&
                window.confirm("Delete this event?") &&
                deleteEvent(lifeEvent, closePopover)
              }
            >
              Delete
            </DeleteButton>
          )}
        </Row>
      </Column>
    </Pane>
  );
};

const deleteEvent = async (lifeEvent: LifeEvent, callback: () => void) => {
  if (lifeEvent.id) {
    await firebase
      .database()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(lifeEvent.memberId)
      .child("events")
      .child(lifeEvent.id)
      .remove();
  }
  callback();
};

const EventPane = ({ event }: { event: Partial<LifeEvent> }) => (
  <Row
    alignItems="flex-start"
    color="black"
    marginBottom={11}
    position="relative"
  >
    <Row centerY marginTop={9}>
      <Pane borderRadius={10} width={4} height={4} backgroundColor="black" />
      <Pane position="absolute" marginLeft={19}>
        {event.timestamp && getFormattedEventDate(event.timestamp)}
      </Pane>
      <Row centerX position="absolute" left={167} width={16} opacity={0.6}>
        {event.type && <LifeEventIcon event={event} />}
      </Row>
    </Row>
    <Pane marginLeft={186}>
      {event && getFormattedEventName(event as LifeEvent)}
    </Pane>
  </Row>
);

export default EditTimelineEventForm;
