import { useRef, useEffect } from "react";

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(function setIsMounted() {
    isMounted.current = true;

    return function cleanupSetIsMounted() {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export default useIsMounted;
