import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { TreeContext } from "./context";

import { getMemberName } from "utils/member";
import api from "utils/api";
import { useIsMobile } from "utils/hooks";
import { arrayToMap } from "utils/common";
import { MemberWithRelationships, Member, Relationships, MemberId } from "core";
import { UIProps } from "components/base/types";

import { H1 } from "components/base/type";
import { Row, Column } from "components/base/layout";

import ContentContainer from "components/ContentContainer";
import EditMemberPanel from "./components/EditMemberPanel";
import Timeline from "./components/Timeline";
import Subtree from "./components/Subtree";

import { Select, Modal } from "antd";

const TreeHeadingContainer = (props: UIProps) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Column width="100%" centerX {...props} />
  ) : (
    <Row justifyContent="space-between" width="100%" centerY {...props} />
  );
};

const fetchAndSetMembers = async (
  setMembers: (members: MemberWithRelationships[]) => void,
  familyId: string
) => {
  const FAMILY_MEMBERS_PATH = `/family/${familyId}/members`;
  interface ApiResult {
    familyMembers: MemberWithRelationships[];
  }
  const result = await api.get<ApiResult>(FAMILY_MEMBERS_PATH);
  setMembers(result.familyMembers);
};

const Tree = ({ userId }: { userId: string }) => {
  const { familyId, baseMemberId, timelineMemberId } = useParams();
  const history = useHistory();
  const [primaryParentId, setPrimaryParentId] = useState<MemberId>(
    baseMemberId || userId
  );
  const [members, setMembers] = useState<MemberWithRelationships[]>([]);

  const membersMap: Map<MemberId, MemberWithRelationships> = useMemo(
    () => arrayToMap(members, "id"),
    [members]
  );

  const userMember: MemberWithRelationships | undefined = membersMap.get(
    userId
  );

  const primaryMember: MemberWithRelationships | undefined = membersMap.get(
    primaryParentId
  );

  const [memberToEdit, setMemberToEdit] = useState<Partial<
    MemberWithRelationships
  > | null>(null);

  const [selectedTimelineMemberId, setSelectedTimelineMemberId] = useState<
    MemberId
  >(timelineMemberId);

  const selectedTimelineMember:
    | MemberWithRelationships
    | undefined = useMemo(
    () => membersMap.get(selectedTimelineMemberId || ""),
    [selectedTimelineMemberId, membersMap]
  );

  useEffect(() => {
    fetchAndSetMembers(setMembers, familyId);
  }, [setMembers, familyId]);

  useEffect(() => {
    const hasPrimaryParentIdChanged =
      primaryParentId && primaryParentId !== baseMemberId;
    const hasTimelineMemberIdChanged =
      !!timelineMemberId &&
      !!selectedTimelineMemberId &&
      selectedTimelineMemberId !== timelineMemberId;

    if (primaryParentId) {
      if (hasPrimaryParentIdChanged) {
        history.push(`/${familyId}/${primaryParentId}`);
      } else if (hasTimelineMemberIdChanged) {
        history.push(
          `/${familyId}/${primaryParentId}/${selectedTimelineMemberId}`
        );
      }
    }
  }, [primaryParentId, familyId, selectedTimelineMemberId]);

  const createOrUpdateMember = (
    editedMember: Partial<Member>,
    relationships: Relationships
  ) => {
    if (editedMember.id) {
      updateMember(editedMember as Member);
    } else {
      createMember(editedMember, relationships);
    }
  };

  const createMember = async (
    editedMember: Partial<Member>,
    relationships: Relationships
  ) => {
    const newMember = await api.post<Member, any>(
      `/family/${familyId}/members`,
      {
        ...relationships,
        familyMember: editedMember,
      }
    );

    if (newMember) {
      const newMemberWithRelationships: MemberWithRelationships = {
        ...newMember,
        ...relationships,
      };

      const updatedMembers: MemberWithRelationships[] = [
        ...members,
        newMemberWithRelationships,
      ].map((eachMember: MemberWithRelationships) => {
        if (newMemberWithRelationships.parents.includes(eachMember.id)) {
          return {
            ...eachMember,
            children: [...eachMember.children, newMemberWithRelationships.id],
          };
        }
        if (newMemberWithRelationships.children.includes(eachMember.id)) {
          return {
            ...eachMember,
            parents: [...eachMember.parents, newMemberWithRelationships.id],
          };
        }
        if (newMemberWithRelationships.partners.includes(eachMember.id)) {
          return {
            ...eachMember,
            partners: [...eachMember.partners, newMemberWithRelationships.id],
          };
        }
        return eachMember;
      });

      setMembers(updatedMembers);
    }
  };

  const updateMember = async (editedMember: Member) => {
    const updatedMember = await api.put<any, Partial<Member>>(
      `/family/${familyId}/members/${editedMember.id}`,
      editedMember
    );
    if (updatedMember) {
      setMembers(
        members.map((member) =>
          member.id === updatedMember.id
            ? { ...member, ...updatedMember }
            : member
        )
      );
    }
  };

  const deleteMember = async (deletedMemberId: string) => {
    await api.delete(`/family/${familyId}/members/${deletedMemberId}`);
    setMembers(members.filter((member) => member.id !== deletedMemberId));
  };

  return (
    <TreeContext.Provider
      value={{
        members,
        membersMap,
        selectedTimelineMember,
        setSelectedTimelineMemberId,
        createMember,
        updateMember,
        createOrUpdateMember,
        deleteMember,
        userMember,
      }}
    >
      <ContentContainer>
        <TreeHeadingContainer>
          <H1 fontFamily="Georgia" fontWeight={400} flexShrink={0}>
            {userMember && `${userMember.firstName}'s Family`}
          </H1>

          <Select
            showSearch
            style={{
              minWidth: 200,
              flexShrink: 0,
              margin: 24,
            }}
            onChange={(selected) => setPrimaryParentId(selected)}
            defaultValue={primaryParentId || ""}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option key="" value="">
              Show all
            </Select.Option>

            {members.map((member) => (
              <Select.Option key={member.id} value={member.id}>
                {getMemberName(member)}
              </Select.Option>
            ))}
          </Select>
        </TreeHeadingContainer>
        {primaryMember && (
          <Subtree
            primaryMember={primaryMember}
            setMemberToEdit={setMemberToEdit}
            setPrimaryParentId={setPrimaryParentId}
            members={members}
            membersMap={membersMap}
          />
        )}

        <TimelineController
          timelineMemberId={timelineMemberId}
          selectedTimelineMemberId={selectedTimelineMemberId}
        />

        <ModalController
          memberToEdit={memberToEdit}
          setMemberToEdit={setMemberToEdit}
        />
      </ContentContainer>
    </TreeContext.Provider>
  );
};

const ModalController = React.memo(
  ({
    memberToEdit,
    setMemberToEdit,
  }: {
    memberToEdit: Partial<MemberWithRelationships> | null;
    setMemberToEdit: (member: Partial<MemberWithRelationships> | null) => void;
  }) => {
    const [isEditPanelVisible, setIsEditPanelVisible] = useState<boolean>(
      false
    );
    const {
      members,
      createOrUpdateMember,
      deleteMember,
      userMember,
    } = useContext(TreeContext);

    useEffect(() => {
      setIsEditPanelVisible(!!memberToEdit);
    }, [memberToEdit]);

    useEffect(() => {
      if (!isEditPanelVisible) {
        const DURATION_MODAL_ANIMATION: number = 500;
        window.setTimeout(() => {
          setMemberToEdit(null);
        }, DURATION_MODAL_ANIMATION);
      }
    }, [isEditPanelVisible, setMemberToEdit]);

    return (
      <Modal
        visible={!!memberToEdit && isEditPanelVisible}
        title={
          memberToEdit && memberToEdit.id
            ? "Edit family member"
            : "Add family member"
        }
        maskClosable
        zIndex={2000}
        width={460}
        footer={null}
        bodyStyle={{ borderRadius: 8 }}
        onCancel={() => setIsEditPanelVisible(false)}
      >
        {memberToEdit && (
          <EditMemberPanel
            key={memberToEdit.id}
            allMembers={members}
            userId={userMember!.id}
            member={memberToEdit}
            createOrUpdateMember={createOrUpdateMember}
            deleteMember={deleteMember}
            closeEditMemberPanel={() => setIsEditPanelVisible(false)}
          />
        )}
      </Modal>
    );
  }
);

const TimelineController = React.memo(
  ({ selectedTimelineMemberId, timelineMemberId }: any) => {
    const [isTimelineOpen, setIsTimelineOpen] = useState<boolean>(
      !!timelineMemberId
    );

    useEffect(() => {
      if (selectedTimelineMemberId) {
        setIsTimelineOpen(true);
      } else {
        setIsTimelineOpen(false);
      }
    }, [selectedTimelineMemberId]);

    return (
      <Timeline
        visible={isTimelineOpen}
        onClose={() => setIsTimelineOpen(false)}
      />
    );
  }
);

export default Tree;
