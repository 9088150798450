import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import * as firebase from "firebase/app";

import styled from "styled-components";
import { useListVals } from "react-firebase-hooks/database";

import { useMembersMap, useUserMember, TreeContext } from "pages/Tree/context";
import { TimelineContext } from "./context";

import {
  MemberWithRelationships,
  MemberId,
  LifeEvent,
  LifeEventType,
  Story,
  Privacy,
} from "core";
import { getMemberName } from "utils/member";

import { Drawer, Popover, Spin } from "antd";
import { Button } from "components/base";
import { Row, Pane } from "components/base/layout";
import DottedBorder from "./DottedBorder";
import EditTimelineEventForm from "./EditTimelineEventForm";
import EventDetailsPane from "./EventDetailsPane";
import getInferredEvents from "./getInferredEvents";

const Title = styled.h1`
  font-family: Georgia;
  font-size: 29px;
  color: black;
`;

const sortTimestamps = (a: LifeEvent, b: LifeEvent) => {
  if (a.timestamp.raw < b.timestamp.raw) {
    return 1;
  }
  if (a.timestamp.raw > b.timestamp.raw) {
    return -1;
  }
  return 0;
};

const Timeline = ({ visible, onClose }: { visible: boolean; onClose: any }) => {
  const [isRendered, setIsRendered] = useState(visible);
  const {
    selectedTimelineMember,
    setSelectedTimelineMemberId,
  }: {
    selectedTimelineMember: MemberWithRelationships | undefined;
    setSelectedTimelineMemberId: (memberId: MemberId) => void;
  } = useContext(TreeContext);

  useEffect(() => {
    if (visible) {
      setIsRendered(true);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && !isRendered) {
      setSelectedTimelineMemberId("");
    }
  }, [visible, isRendered, setSelectedTimelineMemberId]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      afterVisibleChange={(isNowVisible) =>
        !isNowVisible && setIsRendered(false)
      }
      bodyStyle={{ padding: "28px 40px" }}
      style={{ zIndex: 2000 }}
    >
      {isRendered && selectedTimelineMember && (
        <InnerTimeline member={selectedTimelineMember} />
      )}
    </Drawer>
  );
};

const combineEvents = (storedEvents: any[], inferredEvents: LifeEvent[]) => {
  const validStoredEvents = storedEvents.filter((event) => !!event.id);
  const filledInferredEvents = inferredEvents.map((event: LifeEvent) => {
    const relatedEvent = storedEvents.find(
      (storedEvent: any) =>
        storedEvent.stories &&
        Object.values(storedEvent.stories as Story[])[0]?.eventId === event.id
    );
    return relatedEvent ? { ...event, ...relatedEvent } : event;
  });
  return [...validStoredEvents, ...filledInferredEvents];
};

const getAccessibleEvents = (
  events: LifeEvent[],
  member: MemberWithRelationships,
  userMember: MemberWithRelationships
) =>
  events.filter((event: LifeEvent) => {
    switch (event.privacy) {
      case Privacy.Me:
        return (
          event.memberId === userMember.id || event.createdBy === userMember.id
        );
      case Privacy.Public:
      default:
        return true;
    }
  });

const InnerTimeline = ({ member }: { member: MemberWithRelationships }) => {
  const [events, loadingEvents] = useListVals<LifeEvent>(
    firebase
      .database()
      .ref("families")
      .child("kurum")
      .child("entities")
      .child(member.id)
      .child("events")
  );
  const [inferredEvents, setInferredEvents] = useState<LifeEvent[]>([]);

  const userMember: MemberWithRelationships = useUserMember();
  const membersMap: Map<MemberId, MemberWithRelationships> = useMembersMap();

  const syncGetInferredEvents = async () => {
    const result = await getInferredEvents(member, membersMap);
    setInferredEvents(result);
  };

  useEffect(() => {
    syncGetInferredEvents();
  }, [member, membersMap]);

  const combinedEvents: LifeEvent[] = useMemo(
    () =>
      events && inferredEvents ? combineEvents(events, inferredEvents) : [],
    [events, inferredEvents]
  );

  const accessibleEvents: LifeEvent[] = useMemo(
    () => getAccessibleEvents(combinedEvents, member, userMember),
    [combinedEvents]
  );

  return (
    <TimelineContext.Provider value={{ events }}>
      <Title>{getMemberName(member)}</Title>
      {loadingEvents ? (
        <Row centerX>
          <Spin />
        </Row>
      ) : (
        <Pane position="relative">
          <Pane
            position="absolute"
            left={0}
            top={0}
            bottom={0}
            width={1}
            zIndex={-1}
            marginLeft={2}
          >
            <DottedBorder />
          </Pane>
          {accessibleEvents
            .sort(sortTimestamps)
            .map((event: LifeEvent, i: number) => (
              <EventDetailsPane key={i} event={event} member={member} />
            ))}
        </Pane>
      )}
      <NewEventButton />
    </TimelineContext.Provider>
  );
};

const NewEventButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      title="New timeline event"
      visible={isVisible}
      onVisibleChange={setIsVisible}
      content={
        isVisible && (
          <EditTimelineEventForm
            isVisible={isVisible}
            closePopover={() => setIsVisible(false)}
          />
        )
      }
      overlayStyle={{ zIndex: 2001 }}
    >
      <Button>Add Key Event</Button>
    </Popover>
  );
};

export default Timeline;
