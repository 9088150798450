import React from "react";
import { Button } from "antd";
import { UIProps } from "components/base/types";
import { Pane, Column } from "components/base/layout";

export const Field = (props: UIProps) => (
  <Column width="100%" marginBottom={10} {...props} />
);

export const SubmitButton = (props: UIProps) => (
  <Pane is={Button} type="primary" htmlType="submit" {...props} />
);

export const DeleteButton = (props: UIProps) => <Pane is={Button} {...props} />;

export const Label = (props: UIProps) => <Pane fontSize={12} {...props} />;
