import React from "react";
import { LifeEvent, LifeEventType } from "core";
import { UIProps } from "components/base/types";
import Icon from "components/base/Icon";

interface EventProp {
  event: Partial<LifeEvent>;
  type?: LifeEventType;
}

interface TypeProp {
  event?: Partial<LifeEvent>;
  type: LifeEventType;
}

type LifeEventIconProps = (EventProp | TypeProp) & UIProps;

export const LifeEventIcon = ({
  event,
  type,
  ...props
}: LifeEventIconProps) => {
  switch (type || event?.type) {
    case LifeEventType.Moved:
      return <Icon name="fa-map-marker-alt" type="fas" {...props} />;
    case LifeEventType.Wedding:
      return <Icon name="fa-rings-wedding" {...props} />;
    case LifeEventType.School:
      return <Icon name="fa-graduation-cap" {...props} />;
    case LifeEventType.Job:
      return <Icon name="fa-briefcase" {...props} />;
    case LifeEventType.Childbirth:
      return <Icon name="fa-baby-carriage" {...props} />;
    case LifeEventType.Birth:
      return <Icon name="fa-baby" {...props} />;
    case LifeEventType.Death:
      return <Icon name="fa-tombstone-alt" {...props} />;
    case LifeEventType.Photo:
      return <Icon name="fa-image-polaroid" {...props} />;
    case LifeEventType.Trip:
      return <Icon name="fa-plane-departure" {...props} />;
    case LifeEventType.Milestone:
      return <Icon name="fa-star" {...props} />;
  }
  return <></>;
};
