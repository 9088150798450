import { Member } from "core";

export const getMemberName = (member: Member): string => {
  if (member.firstName && member.middleName && member.lastName) {
    return member.firstName + " " + member.middleName + " " + member.lastName;
  }

  if (member.firstName && member.lastName) {
    return member.firstName + " " + member.lastName;
  }
  if (member.firstName || member.nicknames) {
    return member.firstName || `(${member.nicknames})`;
  }
  return "-";
};

export const getMemberNameLine1 = (
  member: Member,
  shouldLeaveBlankIfUnknown?: boolean
): string => {
  if (member.firstName && member.middleName && member.lastName) {
    return member.firstName + " " + member.middleName;
  }

  if (member.firstName && member.lastName) {
    return member.firstName;
  }

  if (member.firstName || member.nicknames) {
    return member.firstName || `(${member.nicknames})`;
  }

  if (shouldLeaveBlankIfUnknown) {
    return "";
  }

  return "-";
};

export const getMemberNameLine2 = (
  member: Member,
  shouldLeaveBlankIfUnknown?: boolean
): string => {
  if (member.lastName) {
    return member.lastName;
  }

  if (shouldLeaveBlankIfUnknown) {
    return "";
  }

  return "-";
};
