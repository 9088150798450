import { MemberWithRelationships, MemberId } from "core";
import { FamilyUnit } from "pages/Tree/types";

const getFamilyUnits = (
  allMembers: MemberWithRelationships[],
  primaryParentId?: MemberId,
  membersToExclude?: MemberId[]
): FamilyUnit | null => {
  if (!primaryParentId) {
    return null;
  }
  const unit: FamilyUnit = getDescendingFamilyUnits(
    allMembers,
    primaryParentId,
    0,
    membersToExclude
  );
  return unit;
};

const getMemberWithLocation = (
  member: MemberWithRelationships,
  relatedMembers?: (MemberWithRelationships | undefined)[]
): MemberWithRelationships => {
  const mutableMember = { ...member };
  let location: string | undefined = member.location;

  relatedMembers &&
    relatedMembers.forEach(
      (relatedMember: MemberWithRelationships | undefined) =>
        (location = location || relatedMember?.location)
    );
  if (location) {
    mutableMember.location = location;
  }
  return mutableMember;
};

// Recursively generate a tree of FamilyUnits from the list
// of members, starting with primaryParentId and going downwards
// through the children.
const getDescendingFamilyUnits = (
  allMembers: MemberWithRelationships[],
  primaryParentId: MemberId,
  generation: number,
  membersToExclude?: MemberId[]
): FamilyUnit => {
  const baseMember = allMembers.find(
    (member: MemberWithRelationships) => member.id === primaryParentId
  );

  const parents: MemberWithRelationships[] = [];
  const children: FamilyUnit[] = [];

  if (!!baseMember) {
    const partnerMember = baseMember.partners.length
      ? allMembers.find(
          (member: MemberWithRelationships) =>
            member.id === baseMember.partners[0]
        )
      : undefined;

    const baseMemberWithLocation = getMemberWithLocation(baseMember, [
      partnerMember,
    ]);

    parents.push(baseMemberWithLocation);

    if (partnerMember !== undefined) {
      const partnerMemberWithLocation = getMemberWithLocation(partnerMember, [
        baseMember,
      ]);
      parents.push(partnerMemberWithLocation);
    }

    baseMember.children.forEach((childKey: string) => {
      if (!membersToExclude || !membersToExclude.includes(childKey)) {
        const childBlock = getDescendingFamilyUnits(
          allMembers,
          childKey,
          generation + 1
        );
        if (childBlock) {
          children.push(childBlock);
        }
      }
    });
  }

  const familyUnit: FamilyUnit = {
    primaryParentId,
    parents,
    children,
    generation,
  };

  return familyUnit;
};

export default getFamilyUnits;
