import React, { useState, useEffect } from "react";
import * as firebase from "firebase";

import { SortableContainer } from "react-sortable-hoc";

import { Story, FirebaseKey, MemberWithRelationships, LifeEvent } from "core";

import { useUpdateEffect } from "utils/hooks";

import { Row, Pane } from "components/base/layout";
import DisplayStoryPane from "./DisplayStoryPane";

const StoriesContainer = ({
  stories,
  event,
  member,
}: {
  stories: Record<FirebaseKey, Story>;
  event: LifeEvent;
  member: MemberWithRelationships;
}) => {
  const [localStories, setLocalStories] = useState<Story[]>(
    Object.values(stories)
  );
  const [isSortingDisabled, setIsSortingDisabled] = useState<boolean>(false);
  const ref = useUpdateEffect(() => {
    setIsSortingDisabled(false);
    setLocalStories(Object.values(stories));
  }, [stories]);

  const saveIndices = async () => {
    const updates: Record<string, number> = localStories.reduce(
      (allUpdates, currentStory) => ({
        ...allUpdates,
        [`${currentStory.id}/index`]: currentStory.index,
      }),
      {}
    );

    console.log("updates", updates);
    firebase
      .database()
      .ref(`families/kurum/entities/${member.id}/events/${event.id}/stories`)
      .update(updates);
  };

  useEffect(() => {
    if (isSortingDisabled) {
      // save to firebase;
      saveIndices();
    }
  }, [localStories]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setIsSortingDisabled(true);
    const getNewIndex = (i: number): number => {
      if (i === oldIndex) {
        return newIndex;
      }
      if (newIndex > oldIndex) {
        if (i > oldIndex && i <= newIndex) {
          return i - 1;
        }
      } else if (newIndex < oldIndex) {
        if (i >= newIndex && i < oldIndex) {
          return i + 1;
        }
      }
      return i;
    };
    setLocalStories(
      localStories.map((story: Story, i: number) => ({
        ...story,
        index: getNewIndex(i),
      }))
    );
  };

  return (
    <StoriesSortableContainer
      stories={localStories}
      onSortEnd={onSortEnd}
      helperClass="sortableContainer"
      isSortingDisabled={isSortingDisabled}
    />
  );
};

const sortStories = (a: Story, b: Story) => {
  if (a.index === b.index) {
    return 0;
  }
  if (a.index === undefined) {
    return 1;
  }
  if (b.index === undefined) {
    return -1;
  }
  if (b.index > a.index) {
    return -1;
  }
  return 1;
};

const StoriesSortableContainer = SortableContainer(
  ({
    stories,
    isSortingDisabled,
  }: {
    stories: Story[];
    isSortingDisabled: boolean;
  }) => {
    return (
      <div>
        {stories.sort(sortStories).map((story: Story, i: number) => (
          <DisplayStoryPane
            key={story.id}
            story={story}
            index={i}
            disabled={isSortingDisabled}
          />
        ))}
      </div>
    );
  }
);

export default StoriesContainer;
