import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Select, Modal } from "antd";
import { getMemberName } from "utils/member";
import api from "utils/api";
import { MemberWithRelationships } from "core";

import { Pane, Column } from "components/base/layout";
import ContentContainer from "components/ContentContainer";

const fetchAndSetMembers = async (
  setMembers: (members: MemberWithRelationships[]) => void,
  familyId: string
) => {
  const FAMILY_MEMBERS_PATH = `/family/${familyId}/members`;
  interface ApiResult {
    familyMembers: MemberWithRelationships[];
  }
  const result = await api.get<ApiResult>(FAMILY_MEMBERS_PATH);
  setMembers(result.familyMembers);
};

const Login = ({ setUserId }: { setUserId: (userId: string) => void }) => {
  const [members, setMembers] = useState<MemberWithRelationships[]>([]);
  const { familyId } = useParams();

  useEffect(() => {
    fetchAndSetMembers(setMembers, familyId);
  }, [setMembers, familyId]);

  return (
    <ContentContainer is={Column} alignItems="flex-start">
      <Modal
        title="Login"
        visible
        zIndex={2000}
        width={460}
        footer={null}
        bodyStyle={{ borderRadius: 8 }}
      >
        <Column>
          {!members.length ? (
            <Pane>Loading...</Pane>
          ) : (
            <>
              <Pane marginBottom={8}>Choose who to log in as</Pane>
              <Select
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 3000 }}
                placeholder="Select a family member"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(selected: string) => setUserId(selected)}
              >
                {members.map((eachMember: MemberWithRelationships) => (
                  <Select.Option key={eachMember.id} value={eachMember.id}>
                    {getMemberName(eachMember)}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
        </Column>
      </Modal>
    </ContentContainer>
  );
};

export default Login;
