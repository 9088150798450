import { TimeDetails } from "core";
import moment from "moment";

export const getFormattedEventDate = (timeDetails: TimeDetails): string => {
  let dateString: string = "";

  if (timeDetails.components) {
    if (timeDetails.components.month && timeDetails.components.day) {
      dateString = moment({
        year: timeDetails.components.year,
        month: timeDetails.components.month - 1,
        day: timeDetails.components.day,
      }).format("MMMM D, y");
    } else if (timeDetails.components.month) {
      dateString = moment({
        year: timeDetails.components.year,
        month: timeDetails.components.month - 1,
      }).format("MMMM y");
    } else {
      dateString = "" + timeDetails.components.year;
    }
  } else {
    dateString = moment(timeDetails.raw).format("MMMM D, y");
  }

  if (timeDetails.isApproximate) {
    dateString = "~" + dateString;
  }
  return dateString;
};
