import React, { useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useLocalStorage } from "@rehooks/local-storage";

import { LOCALSTORAGE_USER_KEY } from "constants/storageKeys";

import AppLayoutContainer from "./AppLayoutContainer";
import NavigationBar from "./NavigationBar";
import PageContent from "./PageContent";

import GlobalThemeProvider from "./GlobalThemeProvider";

import "antd/dist/antd.css";

const App = () => {
  const [userId, setUserId] = useLocalStorage(LOCALSTORAGE_USER_KEY);

  useCallback(() => {
    if (userId) {
      window.analytics.identify(userId);
    }
  }, [userId]);

  return (
    <Router>
      <GlobalThemeProvider>
        <AppLayoutContainer>
          <NavigationBar />
          <PageContent userId={userId} setUserId={setUserId} />
        </AppLayoutContainer>
      </GlobalThemeProvider>
    </Router>
  );
};

export default App;
