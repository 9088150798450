import React from "react";
import { Pane } from "./Pane";
import { UIProps } from "components/base/types";

export const Column = ({
  centerX,
  centerY,
  center,
  grow,
  ...props
}: {
  centerX?: boolean;
  centerY?: boolean;
  center?: boolean;
  grow?: boolean;
} & UIProps) => {
  const alignItems: string | undefined =
    centerX || center ? "center" : undefined;
  const justifyContent: string | undefined =
    centerY || center ? "center" : undefined;

  const flexGrow: number | undefined = grow ? 1 : undefined;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexGrow={flexGrow}
      {...props}
    />
  );
};
