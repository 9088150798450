export type MemberId = string;

export enum MemberFamilyStatus {
  Active = "active",
  CrossedOut = "crossed_out",
}

export interface Member {
  id: MemberId;
  birthDay?: number;
  birthMonth?: number;
  birthTimestamp?: string;
  birthYear?: number;
  birthPlace?: string;
  deathDay?: number;
  deathMonth?: number;
  deathTimestamp?: string;
  deathYear?: number;
  deathPlace?: string;
  firstName?: string;
  gender?: string;
  middleName?: string;
  lastName?: string;
  nicknames?: string;
  location?: string;
  defaultFamilyStatus?: MemberFamilyStatus;
  createdBy?: MemberId;
}

export interface Relationships {
  parents: MemberId[];
  children: MemberId[];
  partners: MemberId[];
  siblings: MemberId[];
}

export interface MemberWithRelationships extends Member, Relationships {}
